import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import cancel from "../../assets/images/Frame 33970.png";
import {
  Box,
  Paper,
  Grid,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
  Stack,
  Divider,
} from "@mui/material";
import BTE from "../../assets/images/Frame_34320[1].png";
import SPECS from "../../assets/images/SPECS Image.svg";
import LEFT from "../../assets/images/Frame 34481 (1).png";
import RIGHT from "../../assets/images/Frame 34483 (1).png";
import CENTER from "../../assets/images/Frame 34501 (1).png";
import RIGHTIMG from "../../assets/images/Left (1).png";
import LEFTIMG from "../../assets/images/Left (2).png";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  DEVICES,
  DEVICES_CHANNEL,
  DEVICES_TUBE,
  DOM_SIZE,
  DOM_TYPE,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import LEFTARROWICON from "../../assets/images/leftIcon.svg";
import RIGHTARROWICON from "../../assets/images/rightarrow.svg";
import LetterAIcon from "../../assets/images/letter-a.svg";
import { useLocation } from "react-router-dom";
import ucl_left from "../../assets/images/ucl_left.svg";
import ucl_right from "../../assets/images/ucl_right.svg";

const AllscreenSize = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1200));
};

const Accordion = styled((props) => (
  <MuiAccordion
    sx={{ border: "1px solid #DDDDDD", borderRadius: "4px", padding: "" }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SelectDeviceui = ({ fields, setFields }) => {
  const { state } = useLocation();
  const [expanded, setExpanded] = useState("panel1");
  const [hovered, setHovered] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  const [selectedTube, setSelectedTube] = useState(
    fields?.right_fitted_device?.device_tube
  );
  const [selectedDome, setSelectedDome] = useState(
    fields?.right_fitted_device?.dom_type
  );
  const [selectedTubeLeft, setSelectedTubeLeft] = useState(
    fields?.left_fitted_device?.device_tube
  );
  const [selectedDomeLeft, setSelectedDomeLeft] = useState(
    fields?.left_fitted_device?.dom_type
  );
  const [selectDevice, setSelectDevice] = useState(state ? state : null);
  const [value, setValue] = useState(0);
  const [values, setValues] = useState(0);
  const [values1, setValues1] = useState(0);
  const [device, setDevice] = useState(
    state
      ? state === LISTENING_SIDE.LEFT
        ? fields?.left_fitted_device?.device_type
          ? fields?.left_fitted_device?.device_type
          : DEVICES.BTE_PRIME
        : fields?.right_fitted_device?.device_type
        ? fields?.right_fitted_device?.device_type
        : DEVICES.BTE_PRIME
      : null
  );

  React.useEffect(() => {
    if (selectDevice === LISTENING_SIDE.LEFT) {
      setFields({
        ...fields,
        left_fitted_device: {
          ...fields.left_fitted_device,
          device_channel:
            device === DEVICES.BTE_OPTIMA
              ? DEVICES_CHANNEL.CHANNEL_8
              : DEVICES_CHANNEL.CHANNEL_32,
        },
      });
    } else if (selectDevice === LISTENING_SIDE.RIGHT) {
      setFields({
        ...fields,
        right_fitted_device: {
          ...fields.right_fitted_device,
          device_channel:
            device === DEVICES.BTE_OPTIMA
              ? DEVICES_CHANNEL.CHANNEL_8
              : DEVICES_CHANNEL.CHANNEL_32,
        },
      });
    } else {
      setFields({
        ...fields,
        right_fitted_device: {
          ...fields.right_fitted_device,
          device_channel:
            device === DEVICES.BTE_OPTIMA
              ? DEVICES_CHANNEL.CHANNEL_8
              : DEVICES_CHANNEL.CHANNEL_32,
        },
        left_fitted_device: {
          ...fields.left_fitted_device,
          device_channel:
            device === DEVICES.BTE_OPTIMA
              ? DEVICES_CHANNEL.CHANNEL_8
              : DEVICES_CHANNEL.CHANNEL_32,
        },
      });
    }
  }, [device]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  const handleChangeTab1 = (event, newValue) => {
    setValues1(newValue);
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const handleMouseOver1 = () => {
    setHovered1(true);
  };

  const handleMouseOut1 = () => {
    setHovered1(false);
  };

  const handleMouseOver2 = () => {
    setHovered2(true);
  };

  const handleMouseOut2 = () => {
    setHovered2(false);
  };

  const handleTubeClickRight = (tubeType) => {
    setFields({
      ...fields,
      right_fitted_device: {
        ...fields.right_fitted_device,
        device_tube: tubeType,
      },
    });
    setSelectedTube(tubeType);
  };

  const handleDomeClickRight = (domType) => {
    setFields({
      ...fields,
      right_fitted_device: {
        ...fields.right_fitted_device,
        dom_type: domType,
      },
    });
    setSelectedDome(domType);
  };

  const handleTubeClickLeft = (tubeType) => {
    setFields({
      ...fields,
      left_fitted_device: {
        ...fields.left_fitted_device,
        device_tube: tubeType,
      },
    });
    setSelectedTubeLeft(tubeType);
  };

  const handleDomeClickLeft = (domType) => {
    setFields({
      ...fields,
      left_fitted_device: {
        ...fields.left_fitted_device,
        dom_type: domType,
      },
    });
    setSelectedDomeLeft(domType);
  };

  const ChartComponent = ({ rightEar, dataB, dataA, dataC, aidedData }) => {
    const boneImg = new Image();
    if (rightEar) {
      boneImg.src = RIGHTARROWICON;
    } else {
      boneImg.src = LEFTARROWICON;
    }
    boneImg.height = 8;
    boneImg.width = 4;
    const aidedImg = new Image();
    aidedImg.src = LetterAIcon;

    const UclImg = new Image();
    if (rightEar) {
      UclImg.src = ucl_right;
    } else {
      UclImg.src = ucl_left;
    }
    UclImg.height = 16;
    UclImg.width = 16;

    const data = {
      labels: ["250", "500", "1k", "2k", "4k", "6k", "8k"],
      datasets: [
        {
          label: "Fitting Range AC",
          data: dataA?.map((item) => item),
          borderColor: "#C24747",
          pointBackgroundColor: "white",
          pointStyle: rightEar ? "circle" : "crossRot",
          hoverBorderColor: rightEar ? "#E62323" : "#0000FF",
          borderColor: rightEar ? "#E62323" : "#0000FF",
          radius: rightEar ? 6 : 7,
        },
        // {
        //   label: "Fitting Target BC",
        //   data: dataB?.map((item) => item),
        //   pointBackgroundColor: "white",
        //   pointStyle: boneImg,
        //   hoverBorderColor: rightEar ? "#E62323" : "#0000FF",
        //   borderColor: rightEar ? "#E62323" : "#0000FF",
        //   radius: rightEar ? 4 : 7,
        //   hoverRadius: rightEar ? 4 : 7,
        //   borderWidth: rightEar ? 1 : 1,
        //   hoverBorderWidth: rightEar ? 1 : 1,
        // },
        // {
        //   label: "Fitting Target UCL",
        //   data: dataC?.map((item) => item),
        //   pointBackgroundColor: "white",
        //   pointStyle: UclImg,
        //   hoverBorderColor: rightEar ? "#E62323" : "#0000FF",
        //   borderColor: rightEar ? "#E62323" : "#0000FF",
        //   radius: rightEar ? 4 : 7,
        //   hoverRadius: rightEar ? 4 : 7,
        //   borderWidth: rightEar ? 1 : 1,
        //   hoverBorderWidth: rightEar ? 1 : 1,
        // },
        {
          label: "Grey Area Upper Bound",
          data: [70, 70, 70, 75, 85, 85, 85, 85],
          borderColor: "rgba(0, 0, 0, 0)",
          backgroundColor: "rgba(200, 200, 200, 0.5)",
          pointRadius: 0,
          fill: "+1",
        },
        {
          label: "Grey Area Lower Bound",
          data: [10, 10, 10, 20, 30, 30, 30, 30],
          borderColor: "rgba(0, 0, 0, 0)",
          backgroundColor: "rgba(200, 200, 200, 0)",
          pointRadius: 0,
          fill: "origin",
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      responsive: true,
      animation: false,
      scales: {
        x: {
          grid: {
            display: true,
          },
          ticks: {
            font: {
              size: 10,
              color: "#666666", // Font size for x-axis labels
            },
            // Padding to move the labels outside the box
          },
        },
        y: {
          min: -10,
          max: 120,
          reverse: true,
          ticks: {
            callback: function (value) {
              return value;
            },
            stepSize: 10,
            autoSkip: false,
            font: {
              size: 8,
            },
          },
          grid: {
            display: true,
          },
        },
      },
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: false,
        },
      },
      elements: {
        line: {
          borderWidth: 1,
          tension: 0.4,
        },
      },
    };

    return (
      <Box
        sx={{
          minHeight: "30vh",
          height: "35vh",
          minWidth: "15vw",
          width: "20vw",
        }}
      >
        <Line data={data} options={options} />
      </Box>
    );
  };

  return (
    // <Box sx={{ maxHeight: "100%", margin: "10px 10px 0px 10px" }}>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        flex: 1,
        justifyContent: "flex-start",
        borderTop: "1px solid #DDDDDD",
        // overflow:"auto",
        // scrollbarWidth:"none"
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} xs={12}>
          <Box sx={{ width: "100%", height: "84vh" }}>
            <Grid container columnSpacing={2}>
              <Grid
                item
                sm={4}
                md={3.5}
                sx={{
                  minHeight: "85vh",
                  maxWidth: "90vh ",
                  alignItems: "center",
                  borderRight: "1px solid #DDDDDD",
                  // padding: "0px 0px 0px 0px",
                  fontFamily: "League Spartan",
                  color: "#4D4D4D",
                }}
              >
                <Box>
                  <Typography sx={{ padding: "8px 24px 8px 24px" }}>
                    Fitting History
                  </Typography>
                  <Box p={4} sx={{ gap: "5px" }}>
                    <Box>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary>
                          <Typography
                            sx={{ paddingLeft: "20px", color: "#666" }}
                          >
                            BTE
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            spacing={2}
                            gap={3}
                            p={3}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flex: "1",
                                padding: "24px 16px",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "16px",
                                height: "25vh",
                                maxWidth: "10vw",
                                marginLeft: "5px",
                                borderRadius: "10px",
                                border:
                                  device == DEVICES.BTE_OPTIMA
                                    ? "1px solid #2D3B67"
                                    : "1px solid #DDDDDD",
                                backgroundColor:
                                  device == DEVICES.BTE_OPTIMA && "#EDF0F7",
                              }}
                              onMouseOver={handleMouseOver}
                              onMouseOut={handleMouseOut}
                            >
                              <Box sx={{ alignItems: "center" }}>
                                <Box>
                                  <img
                                    src={BTE}
                                    alt="LeftEar"
                                    style={{ width: "10vw", height: "10vh" }}
                                  />
                                </Box>
                              </Box>
                              <Typography
                                sx={{ textAlign: "center" }}
                                variant="h5"
                                component="div"
                              >
                                BTE OPTIMA
                                {hovered && (
                                  <Grid
                                    mt={2}
                                    container
                                    sx={{
                                      display: "flex",
                                      width: "9vw",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.RIGHT);
                                        setDevice(DEVICES.BTE_OPTIMA);
                                        setFields({
                                          ...fields,
                                          right_fitted_device: {
                                            ...fields.right_fitted_device,
                                            device_type: DEVICES.BTE_OPTIMA,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={RIGHT}
                                        alt="RightEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.BOTH);
                                        setDevice(DEVICES.BTE_OPTIMA);
                                        setFields({
                                          ...fields,
                                          right_fitted_device: {
                                            ...fields.right_fitted_device,
                                            device_type: DEVICES.BTE_OPTIMA,
                                          },
                                          left_fitted_device: {
                                            ...fields.left_fitted_device,
                                            device_type: DEVICES.BTE_OPTIMA,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={CENTER}
                                        alt="CenterEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.LEFT);
                                        setDevice(DEVICES.BTE_OPTIMA);
                                        setFields({
                                          ...fields,
                                          left_fitted_device: {
                                            ...fields.left_fitted_device,
                                            device_type: DEVICES.BTE_OPTIMA,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={LEFT}
                                        alt="LeftEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flex: "1",
                                padding: "24px 16px",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "16px",
                                height: "25vh",
                                maxWidth: "10vw",
                                borderRadius: "10px",
                                border:
                                  device == DEVICES.BTE_PRIME
                                    ? "1px solid #2D3B67"
                                    : "1px solid #DDDDDD",
                                backgroundColor:
                                  device == DEVICES.BTE_PRIME && "#EDF0F7",
                              }}
                              onMouseOver={handleMouseOver1}
                              onMouseOut={handleMouseOut1}
                            >
                              <Box sx={{ alignItems: "center" }}>
                                <Box>
                                  <img
                                    src={BTE}
                                    alt="LeftEar"
                                    style={{ width: "10vw", height: "10vh" }}
                                  />
                                </Box>
                              </Box>
                              <Typography
                                sx={{ textAlign: "center" }}
                                variant="h5"
                                component="div"
                              >
                                BTE PRIME
                                {hovered1 && (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      width: "9vw",
                                      height: "25vh",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.RIGHT);
                                        setDevice(DEVICES.BTE_PRIME);
                                        setFields({
                                          ...fields,
                                          right_fitted_device: {
                                            ...fields.right_fitted_device,
                                            device_type: DEVICES.BTE_PRIME,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={RIGHT}
                                        alt="RightEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.BOTH);
                                        setDevice(DEVICES.BTE_PRIME);
                                        setFields({
                                          ...fields,
                                          right_fitted_device: {
                                            ...fields.right_fitted_device,
                                            device_type: DEVICES.BTE_PRIME,
                                          },
                                          left_fitted_device: {
                                            ...fields.left_fitted_device,
                                            device_type: DEVICES.BTE_PRIME,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={CENTER}
                                        alt="CenterEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectDevice(LISTENING_SIDE.LEFT);
                                        setDevice(DEVICES.BTE_PRIME);
                                        setFields({
                                          ...fields,
                                          left_fitted_device: {
                                            ...fields.left_fitted_device,
                                            device_type: DEVICES.BTE_PRIME,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={LEFT}
                                        alt="LeftEar"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box mt={2}>
                      <Accordion>
                        <AccordionSummary>
                          <Typography
                            sx={{ paddingLeft: "20px", color: "#666" }}
                          >
                            RIC
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid>
                            <Paper
                              sx={{
                                display: "flex",
                                flex: "1",
                                padding: "24px 16px",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "16px",
                                height: "154px",
                                width: "174px",
                              }}
                            >
                              <Grid direction={"row"}>
                                <Box sx={{ height: "70px", width: "160px" }}>
                                  <img
                                    src={BTE}
                                    alt="LeftEar"
                                    style={{ width: "160px", height: "80px" }}
                                  />
                                </Box>
                              </Grid>
                              <Typography variant="h6" component="div">
                                BTE OPTIMA
                              </Typography>
                            </Paper>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box mt={2}>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary>
                          <Typography
                            sx={{ paddingLeft: "20px", color: "#666" }}
                          >
                            SPECTACLES{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid>
                            <Box
                              sx={{
                                display: "flex",
                                // flex: "1",
                                // padding: "24px 16px",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "16px",
                                height: "23vh",
                                maxWidth: "10vw",
                                marginLeft: "5px",
                                borderRadius: "10px",
                                border:
                                  device == DEVICES.BTE_OPTIMA
                                    ? "1px solid #2D3B67"
                                    : "1px solid #DDDDDD",
                                backgroundColor:
                                  device == DEVICES.BTE_OPTIMA && "#EDF0F7",
                              }}
                              onMouseOver={handleMouseOver2}
                              onMouseOut={handleMouseOut2}
                            >
                              {/* <Grid direction={"row"}> */}
                              <Box sx={{ height: "70px", width: "160px" }}>
                                <img
                                  src={SPECS}
                                  alt="LeftEar"
                                  style={{ width: "160px", height: "80px" }}
                                />
                              </Box>
                              {/* </Grid> */}
                              <Typography variant="h6">SPECS</Typography>
                              {hovered2 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "8vw",
                                    height: "18vh",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <Box
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectDevice(LISTENING_SIDE.RIGHT);
                                      setDevice(DEVICES.BTE_PRIME);
                                      setFields({
                                        ...fields,
                                        right_fitted_device: {
                                          ...fields.right_fitted_device,
                                          device_type: DEVICES.BTE_PRIME,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={RIGHT}
                                      alt="RightEar"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectDevice(LISTENING_SIDE.BOTH);
                                      setDevice(DEVICES.BTE_PRIME);
                                      setFields({
                                        ...fields,
                                        right_fitted_device: {
                                          ...fields.right_fitted_device,
                                          device_type: DEVICES.BTE_PRIME,
                                        },
                                        left_fitted_device: {
                                          ...fields.left_fitted_device,
                                          device_type: DEVICES.BTE_PRIME,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={CENTER}
                                      alt="CenterEar"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectDevice(LISTENING_SIDE.LEFT);
                                      setDevice(DEVICES.BTE_PRIME);
                                      setFields({
                                        ...fields,
                                        left_fitted_device: {
                                          ...fields.left_fitted_device,
                                          device_type: DEVICES.BTE_PRIME,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={LEFT}
                                      alt="LeftEar"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box mt={2}>
                      <Accordion>
                        <AccordionSummary>
                          <Typography
                            sx={{ paddingLeft: "20px", color: "#666" }}
                          >
                            HEARNU PRO
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography></Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid
                md={8.5}
                sm={8}
                sx={{
                  overflow: "auto",
                  scrollbarWidth: "none",
                  height: "90vh",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{ maxWidth: "100% ", fontFamily: "League Spartan" }}
                >
                  {(selectDevice === LISTENING_SIDE.RIGHT ||
                    selectDevice === LISTENING_SIDE.BOTH) && (
                    <Box
                      mb={2}
                      sx={{
                        height: "100%",
                        minWidth:
                          selectDevice == LISTENING_SIDE.BOTH ? "50%" : "100%",
                        fontFamily: "League Spartan",
                      }}
                    >
                      <Box ml={3}>
                        <img
                          src={RIGHT}
                          alt="RightEar"
                          style={{
                            width: "22px",
                            height: "22px",
                            padding: "3px",
                          }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            color:
                              selectDevice === LISTENING_SIDE.RIGHT ||
                              selectDevice === LISTENING_SIDE.BOTH
                                ? "#C24747"
                                : "#1265A1",
                            fontFamily: "League Spartan",
                            fontWeight: "500",
                            marginLeft: "20px",
                            listStyleType: "disc",
                          }}
                        >
                          {device === DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : "BTE PRIME"}
                          <Button
                            onClick={() => {
                              setFields({
                                ...fields,
                                right_fitted_device: {
                                  device_type: null,
                                  device_tube: null,
                                  device_channel: null,
                                  dom_type: null,
                                  dom_size: null,
                                  device_side: LISTENING_SIDE.RIGHT,
                                  selected: false,
                                },
                              });
                              setSelectedDome(null);
                              setSelectedTube(null);
                            }}
                          >
                            <img
                              src={cancel}
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          </Button>
                        </Typography>
                      </Box>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent:
                            selectDevice === LISTENING_SIDE.BOTH
                              ? "space-around"
                              : "flex-start",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            direction: "column",
                            paddingLeft:
                              selectDevice === LISTENING_SIDE.BOTH
                                ? ""
                                : "35px",
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#666666",
                                fontFamily: "League Spartan",
                                fontWeight: "500",
                                // marginLeft: "33px",
                                marginTop:
                                  selectDevice === LISTENING_SIDE.BOTH
                                    ? "0px"
                                    : "20px",
                                listStyleType: "disc",
                              }}
                            >
                              Tube
                            </Typography>
                          </Box>
                          <Grid
                            container
                            gap={"7px"}
                            sx={{
                              display: "flex",
                              justifyContent:
                                selectDevice === LISTENING_SIDE.BOTH
                                  ? "flex-end"
                                  : "",
                              marginTop: "5px",
                            }}
                          >
                            <Button
                              // variant={
                              //   selectedTube === DEVICES_TUBE.STANDARD
                              //     ? "contained"
                              //     : "outlined"
                              // }

                              onClick={() =>
                                handleTubeClickRight(DEVICES_TUBE.STANDARD)
                              }
                              sx={{
                                fontSize: "14px",
                                width: "83px",
                                height: "35px",
                                backgroundColor:
                                  selectedTube === DEVICES_TUBE.STANDARD
                                    ? "#EDF0F7"
                                    : "white",
                                border:
                                  selectedTube === DEVICES_TUBE.STANDARD
                                    ? "1px solid #2D3B67"
                                    : "1px solid #CCCCCC",
                              }}
                            >
                              <Typography variant="h6">Standard</Typography>
                            </Button>
                            <Button
                              // variant={
                              //   selectedTube === DEVICES_TUBE.THIN
                              //     ? "contained"
                              //     : "outlined"
                              // }
                              onClick={() =>
                                handleTubeClickRight(DEVICES_TUBE.THIN)
                              }
                              sx={{
                                fontSize: "16px",
                                width: "83px",
                                height: "35px",
                                backgroundColor:
                                  selectedTube === DEVICES_TUBE.THIN
                                    ? "#EDF0F7"
                                    : "white",
                                border:
                                  selectedTube === DEVICES_TUBE.THIN
                                    ? "1px solid #2D3B67"
                                    : "1px solid #CCCCCC",
                              }}
                            >
                              <Typography variant="h6">Thin</Typography>
                            </Button>
                          </Grid>
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#666666",
                                fontFamily: "League Spartan",
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              Dome
                            </Typography>
                          </Box>
                          <Stack
                            gap={2}
                            direction={
                              selectDevice === LISTENING_SIDE.BOTH
                                ? "column"
                                : "row"
                            }
                          >
                            <Stack
                              direction={"row"}
                              container
                              gap={"7px"}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                // variant={
                                //   selectedDome === DOM_TYPE.OPEN
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickRight(DOM_TYPE.OPEN)
                                }
                                sx={{
                                  fontSize: "16px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDome === DOM_TYPE.OPEN
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDome === DOM_TYPE.OPEN
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Open</Typography>
                              </Button>
                              <Button
                                // variant={
                                //   selectedDome === DOM_TYPE.CLOSE
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickRight(DOM_TYPE.CLOSE)
                                }
                                sx={{
                                  fontSize: "16px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDome === DOM_TYPE.CLOSE
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDome === DOM_TYPE.CLOSE
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Close</Typography>
                              </Button>
                            </Stack>
                            <Stack
                              direction={"row"}
                              gap={"7px"}
                              display={"flex"}
                              justifyContent={"flex-end"}
                              sx={
                                {
                                  // marginLeft:
                                  //   selectDevice === LISTENING_SIDE.BOTH
                                  //     ? "33px"
                                  //     : "10px",
                                  // marginTop: "5px",
                                }
                              }
                            >
                              <Button
                                // variant={
                                //   selectedDome === DOM_TYPE.POWER
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickRight(DOM_TYPE.POWER)
                                }
                                sx={{
                                  fontSize: "16px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDome === DOM_TYPE.POWER
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDome === DOM_TYPE.POWER
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Power</Typography>
                              </Button>
                              <Button
                                // variant={
                                //   selectedDome === DOM_TYPE.EAR_MOLD
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickRight(DOM_TYPE.EAR_MOLD)
                                }
                                sx={{
                                  fontSize: "15px",
                                  width: "83px",
                                  height: "35px",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  backgroundColor:
                                    selectedDome === DOM_TYPE.EAR_MOLD
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDome === DOM_TYPE.EAR_MOLD
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Ear-mold</Typography>
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          mt={5}
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                          <img
                            src={LEFTIMG}
                            alt="RightEar"
                            style={{ minWidth: "10vw", maxHeight: "20vh" }}
                          />
                        </Stack>
                      </Grid>
                    </Box>
                  )}

                  {selectDevice === LISTENING_SIDE.BOTH && (
                    <Box sx={{ maxHeight: "100%" }}>
                      <Divider orientation="vertical" />
                    </Box>
                  )}

                  {(selectDevice === LISTENING_SIDE.LEFT ||
                    selectDevice === LISTENING_SIDE.BOTH) && (
                    <Box
                      mb={1}
                      sx={{
                        height: "100%",
                        minWidth:
                          selectDevice == LISTENING_SIDE.BOTH ? "50%" : "100%",
                        fontFamily: "League Spartan",
                      }}
                    >
                      <Box ml={3}>
                        <img
                          src={LEFT}
                          alt="LeftEar"
                          style={{
                            marginTop: "2px",
                            width: "15px",
                            height: "15px",
                            // padding: "3px",
                          }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            color:
                              selectDevice === LISTENING_SIDE.LEFT ||
                              selectDevice === LISTENING_SIDE.BOTH
                                ? "#1265A1"
                                : "#C24747",
                            fontFamily: "League Spartan",
                            fontWeight: "500",
                            marginLeft: "20px",
                          }}
                        >
                          {device === DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : "BTE PRIME"}
                          <Button
                            onClick={() => {
                              setFields({
                                ...fields,
                                left_fitted_device: {
                                  device_type: null,
                                  device_tube: null,
                                  device_channel: DEVICES_CHANNEL.CHANNEL_32,
                                  dom_type: null,
                                  dom_size: DOM_SIZE.SMALL,
                                  device_side: LISTENING_SIDE.RIGHT,
                                  selected: false,
                                },
                              });
                              setSelectedDomeLeft(null);
                              setSelectedTubeLeft(null);
                            }}
                          >
                            <img
                              src={cancel}
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          </Button>
                        </Typography>
                      </Box>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent:
                            selectDevice === LISTENING_SIDE.BOTH
                              ? "space-around"
                              : "",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            marginTop:
                              selectDevice === LISTENING_SIDE.BOTH
                                ? "31px"
                                : "30px",
                            paddingLeft:
                              selectDevice === LISTENING_SIDE.BOTH
                                ? ""
                                : "27px",
                          }}
                        >
                          <img
                            src={RIGHTIMG}
                            alt="LeftEar"
                            style={{ minWidth: "10vw", maxHeight: "20vh" }}
                          />
                        </Stack>
                        <Stack sx={{ display: "flex", direction: "column" }}>
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#666666",
                                fontFamily: "League Spartan",
                                fontWeight: "500",
                                // marginLeft: "33px",
                                marginTop:
                                  selectDevice === LISTENING_SIDE.BOTH
                                    ? "7px"
                                    : "20px",
                                listStyleType: "disc",
                              }}
                            >
                              Tube
                            </Typography>
                          </Box>

                          <Grid
                            container
                            gap={"7px"}
                            sx={{
                              display: "flex",
                              justifyContent:
                                selectDevice === LISTENING_SIDE.BOTH
                                  ? "flex-end"
                                  : "",
                              marginTop: "5px",
                            }}
                          >
                            <Button
                              // variant={
                              //   selectedTubeLeft === DEVICES_TUBE.STANDARD
                              //     ? "contained"
                              //     : "outlined"
                              // }
                              onClick={() =>
                                handleTubeClickLeft(DEVICES_TUBE.STANDARD)
                              }
                              sx={{
                                fontSize: "14px",
                                width: "83px",
                                height: "35px",
                                backgroundColor:
                                  selectedTubeLeft === DEVICES_TUBE.STANDARD
                                    ? "#EDF0F7"
                                    : "white",
                                border:
                                  selectedTubeLeft === DEVICES_TUBE.STANDARD
                                    ? "1px solid #2D3B67"
                                    : "1px solid #CCCCCC",
                              }}
                            >
                              <Typography variant="h6">Standard</Typography>
                            </Button>
                            <Button
                              // variant={
                              //   selectedTubeLeft === DEVICES_TUBE.THIN
                              //     ? "contained"
                              //     : "outlined"
                              // }
                              onClick={() =>
                                handleTubeClickLeft(DEVICES_TUBE.THIN)
                              }
                              sx={{
                                fontSize: "16px",
                                width: "83px",
                                height: "35px",
                                backgroundColor:
                                  selectedTubeLeft === DEVICES_TUBE.THIN
                                    ? "#EDF0F7"
                                    : "white",
                                border:
                                  selectedTubeLeft === DEVICES_TUBE.THIN
                                    ? "1px solid #2D3B67"
                                    : "1px solid #CCCCCC",
                              }}
                            >
                              <Typography variant="h6">Thin</Typography>
                            </Button>
                          </Grid>
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#666666",
                                fontFamily: "League Spartan",
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              Dome
                            </Typography>
                          </Box>
                          <Stack
                            gap={2}
                            // mb={2}
                            // height={}
                            direction={
                              selectDevice === LISTENING_SIDE.BOTH
                                ? "column"
                                : "row"
                            }
                          >
                            <Stack
                              direction={"row"}
                              container
                              gap={"7px"}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                // variant={
                                //   selectedDomeLeft === DOM_TYPE.OPEN
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickLeft(DOM_TYPE.OPEN)
                                }
                                sx={{
                                  fontSize: "14px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDomeLeft === DOM_TYPE.OPEN
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDomeLeft === DOM_TYPE.OPEN
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Open</Typography>
                              </Button>
                              <Button
                                // variant={
                                //   selectedDomeLeft === DOM_TYPE.CLOSE
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickLeft(DOM_TYPE.CLOSE)
                                }
                                sx={{
                                  fontSize: "16px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDomeLeft === DOM_TYPE.CLOSE
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDomeLeft === DOM_TYPE.CLOSE
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Close</Typography>
                              </Button>
                            </Stack>
                            <Stack
                              direction={"row"}
                              gap={"7px"}
                              display={"flex"}
                              justifyContent={"flex-end"}
                              sx={
                                {
                                  // marginLeft:
                                  //   selectDevice === LISTENING_SIDE.BOTH
                                  //     ? "33px"
                                  //     : "0px",
                                  // marginTop: "5px",
                                }
                              }
                            >
                              <Button
                                // variant={
                                //   selectedDomeLeft === DOM_TYPE.POWER
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickLeft(DOM_TYPE.POWER)
                                }
                                sx={{
                                  fontSize: "16px",
                                  width: "83px",
                                  height: "35px",
                                  backgroundColor:
                                    selectedDomeLeft === DOM_TYPE.POWER
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDomeLeft === DOM_TYPE.POWER
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Power</Typography>
                              </Button>
                              <Button
                                // variant={
                                //   selectedDomeLeft === DOM_TYPE.EAR_MOLD
                                //     ? "contained"
                                //     : "outlined"
                                // }
                                onClick={() =>
                                  handleDomeClickLeft(DOM_TYPE.EAR_MOLD)
                                }
                                sx={{
                                  fontSize: "15px",
                                  width: "83px",
                                  height: "35px",
                                  paddingLeft: "5px",
                                  // paddingRight: "5px",
                                  backgroundColor:
                                    selectedDomeLeft === DOM_TYPE.EAR_MOLD
                                      ? "#EDF0F7"
                                      : "white",
                                  border:
                                    selectedDomeLeft === DOM_TYPE.EAR_MOLD
                                      ? "1px solid #2D3B67"
                                      : "1px solid #CCCCCC",
                                }}
                              >
                                <Typography variant="h6">Ear-mold</Typography>
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Box>
                  )}
                </Stack>
                {(selectDevice === LISTENING_SIDE.LEFT ||
                  selectDevice === LISTENING_SIDE.RIGHT ||
                  selectDevice === LISTENING_SIDE.BOTH) && (
                  <Box
                    sx={{
                      height: "0vh",
                    }}
                  >
                    <Grid container>
                      <Grid
                        sm={6}
                        p={2}
                        md={6}
                        sx={{
                          borderTop: "1px solid #DDDDDD",
                        }}
                      >
                        {selectDevice === LISTENING_SIDE.BOTH ? (
                          <>
                            <Tabs
                              value={values}
                              onChange={handleChangeTab}
                              aria-label="basic tabs example"
                              sx={{ height: "0px" }}
                            >
                              <Tab
                                label="Features"
                                sx={{ fontSize: "16px" }}
                                {...a11yProps(0)}
                              />
                              <Tab
                                label="Fitting Range"
                                sx={{ fontSize: "16px" }}
                                {...a11yProps(1)}
                              />
                            </Tabs>
                            <CustomTabPanel
                              value={values}
                              index={1}
                              sx={{ height: "0px" }}
                            >
                              <Box
                                // p={4}
                                sx={{
                                  display: "flex",
                                  width: "80%",
                                  height: "35vh",
                                  minHeight: "20vh",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  scrollbarWidth: "none",
                                  overflow: "auto",
                                }}
                              >
                                <ChartComponent
                                  rightEar
                                  allowed_ac={fields?.allowed_ac}
                                  allowed_bc={fields?.allowed_bc}
                                  allowed_ucl={fields?.allowed_ucl}
                                  dataA={[
                                    fields?.rf1,
                                    fields?.rf2,
                                    fields?.rf3,
                                    fields?.rf4,
                                    fields?.rf5,
                                    fields?.rf6,
                                    fields?.rf7,
                                  ]}
                                  dataB={[
                                    fields?.brf1,
                                    fields?.brf2,
                                    fields?.brf3,
                                    fields?.brf4,
                                    fields?.brf5,
                                    fields?.brf6,
                                    fields?.brf7,
                                  ]}
                                  dataC={[
                                    fields?.urf1,
                                    fields?.urf2,
                                    fields?.urf3,
                                    fields?.urf4,
                                    fields?.urf5,
                                    fields?.urf6,
                                    fields?.urf7,
                                  ]}
                                />{" "}
                              </Box>
                            </CustomTabPanel>
                            <CustomTabPanel
                              value={values}
                              index={0}
                              sx={{ height: "0px" }}
                            >
                              <List
                                sx={{
                                  listStyleType: "disc",
                                  overflow: "auto",
                                  height: "38vh",
                                  scrollbarWidth: "none",
                                }}
                              >
                                <Box ml={6} component={"div"}>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      Device can program up to 8 channel.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      Two listening modes for different
                                      environments.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      High-end audio processing for noise
                                      reduction and clear sound.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      45 min. charging gives you up to 10 hrs.
                                      playtime.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      9 grams lightweight design with IPX6 water
                                      resistance.
                                    </Typography>
                                  </ListItem>
                                </Box>
                                <Button
                                  sx={{
                                    textDecoration: "underline",
                                    fontSize: "12px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Typography variant="h6">
                                    {" "}
                                    Product Detail.
                                  </Typography>
                                </Button>
                              </List>
                            </CustomTabPanel>
                          </>
                        ) : (
                          <>
                            <Tabs
                              sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                width: "500px",
                                tabSize: "10px",
                              }}
                              value={value}
                            >
                              <Tab
                                value={value}
                                sx={{ fontSize: "16px" }}
                                label="Features"
                              />
                            </Tabs>

                            <List
                              sx={{
                                listStyleType: "disc",
                                overflow: "auto",
                                height: "38vh",
                                scrollbarWidth: "none",
                              }}
                            >
                              <Box p={4} ml={6} component={"div"}>
                                <ListItem sx={{ display: "list-item" }}>
                                  <Typography>
                                    Device can program up to 8 channel.
                                  </Typography>
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                  <Typography>
                                    Two listening modes for different
                                    environments.
                                  </Typography>
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                  <Typography>
                                    High-end audio processing for noise
                                    reduction and clear sound.
                                  </Typography>
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                  <Typography>
                                    45 min. charging gives you up to 10 hrs.
                                    playtime.
                                  </Typography>
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                  <Typography>
                                    9 grams lightweight design with IPX6 water
                                    resistance.
                                  </Typography>
                                </ListItem>
                              </Box>
                              <Button
                                sx={{
                                  textDecoration: "underline",
                                  fontSize: "12px",
                                  marginLeft: "10px",
                                }}
                              >
                                <Typography variant="h6">
                                  {" "}
                                  Product Detail.
                                </Typography>
                              </Button>
                            </List>
                          </>
                        )}
                      </Grid>

                      <Grid
                        sm={6}
                        p={2}
                        md={6}
                        xs={2}
                        sx={{
                          borderLeft: "1px solid #DDDDDD",
                          borderTop: "1px solid #DDDDDD",
                          overflow: "hidden",
                          minHeight: "55vh",
                        }}
                      >
                        {/* <Box width={"100%"} height="100%" sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}> */}
                        {selectDevice === LISTENING_SIDE.BOTH ? (
                          <>
                            <Tabs
                              value={values1}
                              onChange={handleChangeTab1}
                              aria-label="basic tabs example"
                              sx={{ height: "0px" }}
                            >
                              <Tab
                                label="Features"
                                sx={{ fontSize: "16px" }}
                                {...a11yProps(0)}
                              />
                              <Tab
                                label="Fitting Range"
                                sx={{ fontSize: "16px" }}
                                {...a11yProps(1)}
                              />
                            </Tabs>
                            <CustomTabPanel
                              value={values1}
                              index={1}
                              sx={{ height: "0px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "80%",
                                  height: "35vh",
                                  minHeight: "20vh",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  scrollbarWidth: "none",
                                  overflow: "auto",
                                }}
                              >
                                <ChartComponent
                                  allowed_ac={fields?.allowed_ac}
                                  allowed_bc={fields?.allowed_bc}
                                  allowed_ucl={fields?.allowed_ucl}
                                  dataA={[
                                    fields?.lf1,
                                    fields?.lf2,
                                    fields?.lf3,
                                    fields?.lf4,
                                    fields?.lf5,
                                    fields?.lf6,
                                    fields?.lf7,
                                  ]}
                                  dataB={[
                                    fields?.blf1,
                                    fields?.blf2,
                                    fields?.blf3,
                                    fields?.blf4,
                                    fields?.blf5,
                                    fields?.blf6,
                                    fields?.blf7,
                                  ]}
                                  dataC={[
                                    fields?.ulf1,
                                    fields?.ulf2,
                                    fields?.ulf3,
                                    fields?.ulf4,
                                    fields?.ulf5,
                                    fields?.ulf6,
                                    fields?.ulf7,
                                  ]}
                                />
                              </Box>
                            </CustomTabPanel>
                            <CustomTabPanel
                              value={values1}
                              index={0}
                              // sx={{ height: "0px" }}
                            >
                              <List
                                sx={{
                                  listStyleType: "disc",
                                  overflow: "auto",
                                  height: "38vh",
                                  scrollbarWidth: "none",
                                }}
                              >
                                <Box ml={6} component={"div"}>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      Device can program up to 8 channel.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      Two listening modes for different
                                      environments.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      High-end audio processing for noise
                                      reduction and clear sound.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      45 min. charging gives you up to 10 hrs.
                                      playtime.
                                    </Typography>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Typography>
                                      9 grams lightweight design with IPX6 water
                                      resistance.
                                    </Typography>
                                  </ListItem>
                                </Box>
                                <Button
                                  sx={{
                                    textDecoration: "underline",
                                    fontSize: "12px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Typography variant="h6">
                                    {" "}
                                    Product Detail.
                                  </Typography>
                                </Button>
                              </List>
                            </CustomTabPanel>
                          </>
                        ) : (
                          <>
                            <Tabs
                              sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                width: "500px",
                              }}
                              value={value}
                            >
                              <Tab
                                sx={{ fontSize: "16px" }}
                                label="Fitting Range"
                              />
                            </Tabs>
                            <Box
                              p={4}
                              sx={{
                                display: "flex",
                                width: "80%",
                                height: "40vh",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "auto",
                                scrollbarWidth: "none",
                              }}
                            >
                              {selectDevice === LISTENING_SIDE.RIGHT ? (
                                <ChartComponent
                                  allowed_ac={fields?.allowed_ac}
                                  allowed_bc={fields?.allowed_bc}
                                  allowed_ucl={fields?.allowed_ucl}
                                  // selectDevice === { LISTENING_SIDE.RIGHT ? rightEar :null}

                                  dataA={[
                                    fields?.rf1,
                                    fields?.rf2,
                                    fields?.rf3,
                                    fields?.rf4,
                                    fields?.rf5,
                                    fields?.rf6,
                                    fields?.rf7,
                                  ]}
                                  dataB={[
                                    fields?.brf1,
                                    fields?.brf2,
                                    fields?.brf3,
                                    fields?.brf4,
                                    fields?.brf5,
                                    fields?.brf6,
                                    fields?.brf7,
                                  ]}
                                  dataC={[
                                    fields?.urf1,
                                    fields?.urf2,
                                    fields?.urf3,
                                    fields?.urf4,
                                    fields?.urf5,
                                    fields?.urf6,
                                    fields?.urf7,
                                  ]}
                                  rightEar
                                />
                              ) : (
                                <ChartComponent
                                  allowed_ac={fields?.allowed_ac}
                                  allowed_bc={fields?.allowed_bc}
                                  allowed_ucl={fields?.allowed_ucl}
                                  // selectDevice === { LISTENING_SIDE.RIGHT ? rightEar :null}

                                  dataA={[
                                    fields?.lf1,
                                    fields?.lf2,
                                    fields?.lf3,
                                    fields?.lf4,
                                    fields?.lf5,
                                    fields?.lf6,
                                    fields?.lf7,
                                  ]}
                                  dataB={[
                                    fields?.blf1,
                                    fields?.blf2,
                                    fields?.blf3,
                                    fields?.blf4,
                                    fields?.blf5,
                                    fields?.blf6,
                                    fields?.blf7,
                                  ]}
                                  dataC={[
                                    fields?.ulf1,
                                    fields?.ulf2,
                                    fields?.ulf3,
                                    fields?.ulf4,
                                    fields?.ulf5,
                                    fields?.ulf6,
                                    fields?.ulf7,
                                  ]}
                                />
                              )}
                            </Box>
                          </>
                        )}
                        {/* </Box> */}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
    // {/* </Box> */}
  );
};

export default SelectDeviceui;
