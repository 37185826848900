class Fig6 {
  constructor() {
    this.low_target = 0.0;
    this.mid_target = 0.0;
    this.high_target = 0.0;
  }

  calc_target_array(ag) {
    if (ag <= 20) {
      this.low_target = 0;
    } else if (ag <= 60) {
      this.low_target = (ag - 20) * 0.7;
    } else {
      this.low_target = (ag - 20 - 0.5 * (ag - 60)) * 0.7;
    }

    if (ag < 20) {
      this.mid_target = 0;
    } else if (ag < 60) {
      this.mid_target = 0.6 * (ag - 20) * 0.8;
    } else {
      this.mid_target = 0.8 * (ag - 23) * 0.8;
    }

    if (ag < 40) {
      this.high_target = 0;
    } else {
      this.high_target = 0.2 * (ag - 40);
    }

    let ratio_low_mid = (this.mid_target - this.low_target) / 25.0;
    this.low_target = this.mid_target - 15 * ratio_low_mid;

    let ratio_mid_high = (this.high_target - this.mid_target) / 25.0;
    this.high_target = this.mid_target + 15 * ratio_mid_high;

    return [this.low_target, this.mid_target, this.high_target];
  }
}

function calculate_exp_cr(
  env_dBex,
  exp_end_knee,
  tkgn,
  tk,
  cr,
  bolt,
  output_dBex
) {
  let pdb = env_dBex;
  let tk_tmp = tk;

  if (tk_tmp + tkgn > bolt) {
    tk_tmp = bolt - tkgn;
  }

  let tkgo = tkgn + tk_tmp * (1.0 - 1.0 / cr);
  let pblt = cr * (bolt - tkgo);
  let cr_const = 1.0 / cr - 1.0;

  let gain_at_exp_end_knee = tkgn;
  if (tk_tmp < exp_end_knee) {
    gain_at_exp_end_knee = cr_const * exp_end_knee + tkgo;
  }

  let gdb = output_dBex - pdb;

  let exp_cr;
  if (pdb < exp_end_knee) {
    let exp_cr_const = (gain_at_exp_end_knee - gdb) / (exp_end_knee - pdb);
    exp_cr = 1.0 / (exp_cr_const + 1.0);
  } else {
    exp_cr = 1.0;
  }

  return exp_cr;
}

function WDRC_circuit_gain(env_dBcr, exp_cr, exp_end_knee, tkgn, tk, cr, bolt) {
  let gdb = 0.0;
  let tkgo = 0.0;
  let pblt = 0.0;
  let pdb = env_dBcr;
  let tk_tmp = tk;

  if (tk_tmp + tkgn > bolt) {
    tk_tmp = bolt - tkgn;
  }

  tkgo = tkgn + tk_tmp * (1.0 - 1.0 / cr);
  pblt = cr * (bolt - tkgo);
  let cr_const = 1.0 / cr - 1.0;

  let gain_at_exp_end_knee = tkgn;
  if (tk_tmp < exp_end_knee) {
    gain_at_exp_end_knee = cr_const * exp_end_knee + tkgo;
  }

  let exp_cr_const = 1.0 / Math.max(0.01, exp_cr) - 1.0;
  let regime = 0;
  if (pdb < exp_end_knee) {
    gdb = gain_at_exp_end_knee - (exp_end_knee - pdb) * exp_cr_const;
    regime = 0;
  } else if (pdb < tk_tmp && cr >= 1.0) {
    gdb = tkgn;
    regime = 1;
  } else if (pdb > pblt) {
    gdb = bolt + (pdb - pblt) / 10.0 - pdb;
    regime = 3;
  } else {
    gdb = cr_const * pdb + tkgo;
    regime = 2;
  }

  let output_dB = pdb + gdb;
  return [output_dB, regime];
}

function find_best_cr(
  desired_output_dB,
  env_dBcr,
  exp_cr,
  exp_end_knee,
  tkgn,
  initial_tk,
  bolt
) {
  let best_cr = null;
  let best_difference = Infinity;
  let best_output = null;
  let tk = initial_tk;
  let tk_limit = 59;

  while (tk >= tk_limit) {
    for (let cr = 0.1; cr <= 3.0; cr += 0.1) {
      let output = WDRC_circuit_gain(
        env_dBcr,
        exp_cr,
        exp_end_knee,
        tkgn,
        tk,
        cr,
        bolt
      );
      let output_dB = output[0];
      let difference = Math.abs(output_dB - desired_output_dB);

      if (difference < best_difference) {
        best_difference = difference;
        best_cr = cr;
        best_output = output;
        var best_tk = tk;
      }

      if (difference === 0) {
        return [best_cr, best_output, tk];
      }
    }

    tk -= 1;
  }

  return [best_cr, best_output, best_tk];
}

export const Calculation = (ag) => {

  console.log("this is ag",ag)

  let fig6 = new Fig6();

  let [low_target, mid_target, high_target] = fig6.calc_target_array(ag);

  let env_dBex = 40;
  let env_dBcr = 95;
  let exp_cr = 1.8;
  let exp_end_knee = 55;
  let initial_tk = 70;
  let tk = initial_tk;
  let cr = 0.8;
  let bolt = 130;

  let gainex = Math.round(low_target);
  let tkgn = Math.round(mid_target);
  let Gaincr = Math.round(high_target);

  let output_dBex = env_dBex + gainex;
  let desired_output_dB = env_dBcr + Gaincr;

  exp_cr = calculate_exp_cr(
    env_dBex,
    exp_end_knee,
    tkgn,
    tk,
    cr,
    bolt,
    output_dBex
  );


  let [best_cr, best_output, final_tk] = find_best_cr(
    desired_output_dB,
    env_dBcr,
    exp_cr,
    exp_end_knee,
    tkgn,
    initial_tk,
    bolt
  );


  let MPO = ag + 40;
  console.log("Target Dynamic Range Compression");
  console.log("--------------------------------");
  console.log(`ER: ${exp_cr}`);
  console.log(`Eknee: ${exp_end_knee}`);
  console.log(`LG: ${tkgn}`);
  console.log(`Cknee: ${final_tk}`);
  console.log(`CR: ${best_cr}`);
  console.log(`MPO: ${MPO}`);

  return {
    ER: exp_cr,
    Eknee: exp_end_knee,
    exp_end_knee: tkgn,
    Cknee: final_tk,
    CR: best_cr,
    MPO: MPO,
  };
};
