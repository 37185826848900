import {
  OpenInNew,
  PeopleAltSharp,
  PersonAddOutlined,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import SubmitButton from "../../components/button/SubmitButton";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../components/inputs/SearchBar";

import { USER_ROLES } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";
import { titleCase, toTitleCase } from "../../utils/helper";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import { CenteredBox } from "../../components/layouts/common/boxes";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const HeaderComponent = memo(({ filters, setFilters, onCreateBtnClick }) => (
  <Box sx={{ display: "flex", width: "50%" }}>
    <Box sx={{ display: "flex", flex: 1 }}>
      <AsynSearchBar
        name="patient-searhc-bar"
        id="patient-search-bar"
        for="patient"
        fullWidth
        title="Search  Patient"
        size="small"
        InputProps={{
          startAdornment: <Search sx={{ mr: 2 }} />,
        }}
        placeholder={"Search Patient"}
        defaultValue={filters.search}
        onChange={(changedVal) => {
          setFilters({ ...filters, search: changedVal });
        }}
      />
    </Box>
    <Box sx={{ display: "flex", marginRight: "20px" }} ml={3}>
      <Button
        LinkComponent={Link}
        to="/new-patient"
        variant="outlined"
        pb={0}
        sx={{ pb: 0, marginRight: "10px" }}
      >
        <Typography variant="h4" mr={2} lineHeight="100%" fontWeight={500}>
          <PersonAddOutlined />
        </Typography>
        <Typography variant="h4" lineHeight="100%" fontWeight={500}>
          New Patient
        </Typography>
      </Button>
    </Box>
  </Box>
));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  columns,
}) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  return (
    <ModuleContainer
      headrComponentProps={{ filters, setFilters }}
      HeaderComponent={HeaderComponent}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflow: "hidden",
          flexDirection: "column",
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <DataTable
          customRows={true}
          columns={columns}
          rows={list.result ? list.result : []}
          count={list.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
    </ModuleContainer>
  );
};
export default ListUi;
