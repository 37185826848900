import { Avatar, Box, Button, CircularProgress, IconButton, Typography } from "@mui/material"
import { getFile } from "../../apis/file.api"
import { useEffect, useState } from "react"
import fileDownload from 'js-file-download'
import { Download } from "@mui/icons-material"
const ImageComponent = ({ src, alt, isAvatar, download, avtarTitle, imgStyle = {}, ...props }) => {
    const [img, setImage] = useState(false)
    const [stream, setStream] = useState(null)
    const [loading, setLoading] = useState(true)

    const fetchImage = async () => {
        const mediaStream = await getFile(src)


        if (window.URL && window.URL.createObjectURL) {
            setStream(mediaStream)
            setImage(window.URL.createObjectURL(mediaStream));
        }
        else if (window.webkitURL && window.webkitURL.createObjectURL) {
            setStream(mediaStream)
            setImage(window.webkit.createObjectURL(mediaStream))
        }
        else {
            const errMessage = "Your browsers does not support URL.createObjectURL.";
        }


        setLoading(false)
    }
    useEffect(() => {
        fetchImage()
    }, [src])


    if (loading)
        return <CircularProgress />

    if (isAvatar) {
        return <Avatar src={img} sizes="large" sx={{ width: "130px", height: "130px", bgcolor: "primary.main" }}>
            <Typography sx={{ fontSize: "3.5rem" }}>
                {
                    avtarTitle
                }
            </Typography>
        </Avatar>
    }
    return <Box sx={{ width: "100%", height: "100%", background: 'lightgrey', position: "relative", ...props.sx }} {...props}>
        <img src={img} style={{ height: "100%", width: "100%", objectFit: "contain", ...imgStyle }} />
        {download && <IconButton
            sx={{ position: "absolute", top: "0px", right: "0px" }}
            onClick={() => {
                const splittedPath = src.split('.')
                const extension = splittedPath[splittedPath.length - 1]

                fileDownload(stream, "Event." + extension.slice(0, extension.indexOf("?")))
            }}>
            <Download color="info" />
        </IconButton>}
    </Box>

}
export default ImageComponent