import { actions } from "../../utils/constants";


const initialState = {
    message: "",
    variant: "success",
    key: 1

}
const snackBarReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.CALL_SNACKBAR_DATA: return { ...state, message: action.message, variant: action.variant, key: Math.random() };
        default: return { ...state }
    }

}
export default snackBarReducer