import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FittingChartArea from "./FittingChartArea";
import { callApiAction } from "../../store/actions/commonAction";
import {
  getComplain,
  getComplainApi,
  getComplainByIdApi,
} from "../../apis/complain.api";
import { getTopicApi } from "../../apis/topic.api";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import MenuItemgraphImage from "../../assets/images/MenuItemgraphImage.svg";
import { LISTENING_SIDE } from "../../utils/patient.constants";
import SubmitButton from "../../components/button/SubmitButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { changeByteValue } from "../../store/actions/fittingAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const ConfigAssistantController = ({ mutedHandle, isReset, setIsReset }) => {
  const [edgeVal, setEdgeVal] = useState(50);
  const { fittingLeft } = useSelector((state) => state);
  const { fitting } = useSelector((state) => state);
  const [loadingT, setLoadingT] = useState(true);
  const [loadingI, setLoadingI] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const [topic, setTopic] = useState([]);
  const [issues, setIssues] = useState([]);
  const [solution, setSolution] = useState([]);
  const [topicindex, setTopicindex] = useState();
  const [issuesIndex, setIssuesIndex] = useState(null);
  const [solutionindex, setSolutionindex] = useState(null);
  const [side, setSide] = useState(null);
  const [applyLoading, setApplyLoading] = useState(false);

  const [undoArray, setUndoArray] = useState([]);
  const dispatch = useDispatch();
  console.log("Data fetched:");

  useEffect(() => {
    if (isReset) {
      console.log("this is call");
      setUndoArray([]);
      setIsReset(false);
    }
  }, [isReset]);

  const fetchTopics = () => {
    dispatch(
      callApiAction(
        async () => await getTopicApi(),
        async (response) => {
          setTopic([...response?.result]);
          setLoadingT(false);
        },
        (err) => {
          setLoadingT(false);
        }
      )
    );
  };

  const fetchComplainById = (id) => {
    setLoadingI(true);
    dispatch(
      callApiAction(
        async () => await getComplainByIdApi({ id }),
        async (response) => {
          console.log("response", response);
          setIssues([...response]);
          setLoadingI(false);
        },
        (err) => {
          setIssues([...issues, err]);
          setLoadingI(false);
        }
      )
    );
  };

  const Accordion = styled((props) => (
    <MuiAccordion
      sx={{ border: "1px solid #DDDDDD", borderRadius: "4px", padding: "" }}
      disableGutters
      elevation={0}
      square
      {...props}
    />
  ))((props) => ({
    backgroundColor: props.id === solutionindex ? "#EDF0F7" : "",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    // "& .MuiAccordionSummary-content": {
    //   marginLeft: theme.spacing(1),
    // },
  }));

  const AccordionSummary = styled(
    (props) => (
      console.log("this is props", props),
      (
        <MuiAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
          {...props}
        />
      )
    )
  )((props) => ({
    backgroundColor: props.id === solutionindex ? "#EDF0F7" : "",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    // "& .MuiAccordionSummary-content": {
    //   marginLeft: theme.spacing(1),
    // },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const fetchComplainBy_Id = (id) => {
    setLoadingS(true);
    dispatch(
      callApiAction(
        async () => await getComplain({ id }),
        async (response) => {
          console.log("response", response);
          setSolution(response?.complaint_solution);
          setLoadingS(false);
        },
        (err) => {
          setLoadingS(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <>
      <Box>
        <Box maxHeight={"60vh"}>
          <Grid xs={12} md={12} container>
            <Grid md={5.7} xs={5.7}>
              <FittingChartArea
                mutedHandle={mutedHandle}
                fitting={fitting}
                edgeVal={edgeVal}
              />
            </Grid>
            <Grid md={0.6} xs={0.6}>
              <Box
                sx={{
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                    <MenuItem value={10}>
                      <img src={MenuItemgraphImage} alt="SPEAKERIMAGE" />
                    </MenuItem>
                  </Select>
                </Box> */}
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#4A4A4A",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        50dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#969696",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        65dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#CCCCCC",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        80dB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={5.7} md={5.7}>
              <FittingChartArea
                mutedHandle={mutedHandle}
                fitting={fittingLeft}
                edgeVal={edgeVal}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        ml={3}
        mt={2}
        sx={{
          height: "43vh",
          scrollbarWidth: "none",
          border: "1px solid #DDDDDD",
          borderRadius: "4px",
        }}
        // overflow={"auto"}
      >
        <Grid container xs={12} md={12}>
          <Grid
            xs={2}
            md={2}
            gap={3}
            mb={4}
            sx={{
              height: "43vh",
              scrollbarWidth: "none",
              borderRight: "1px solid #DDDDDD",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                sx={{
                  height: "6vh",
                  padding: "8px",
                  color: "#000",
                  fontFamily: "League spartan",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  borderBottom: "1px solid #DDDDDD",
                }}
              >
                Topics
              </Typography>
              {loadingT ? (
                <CenteredBox>
                  <CircularProgress />
                </CenteredBox>
              ) : (
                <Box
                  p={1}
                  overflow={"auto"}
                  sx={{ width: "100%", scrollbarWidth: "none" }}
                  height={"36vh"}
                >
                  {topic?.map((item, index) => (
                    <Button
                      onClick={() => {
                        setTopicindex(index);
                        setIssuesIndex(null);
                        setSolution([]);
                        setSolutionindex(null);
                        fetchComplainById(item?._id);
                      }}
                      sx={{
                        height: "7vh",
                        color: "#4D4D4D",
                        fontSize: "18PX",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        fontFamily: "League spartan",
                        fontStyle: "normal",
                        fontWeight: 400,
                        borderBottom: "1px solid #DDDDDD",
                        backgroundColor:
                          topicindex === index ? "#EDF0F7" : "white",
                        border: topicindex === index ? "1px solid #2D3B67" : "",
                        borderRadius: topicindex === index ? "2px" : "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "League spartan",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {item?.title}
                      </Typography>
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            xs={5}
            md={5}
            gap={3}
            mb={4}
            sx={{
              height: "43vh",
              scrollbarWidth: "none",
              borderRight: "1px solid #DDDDDD",
            }}
          >
            <Grid width={"100%"}>
              <Typography
                sx={{
                  height: "6vh",
                  padding: "8px",
                  color: "#000",
                  fontFamily: "League spartan",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  borderBottom: "1px solid #DDDDDD",
                }}
              >
                Issues
              </Typography>
            </Grid>
            {loadingI ? (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            ) : (
              <Box
                p={2}
                overflow={"auto"}
                sx={{ width: "100%", scrollbarWidth: "none" }}
                height={"36vh"}
              >
                {issues?.map((item, index) => (
                  <Button
                    onClick={() => {
                      setIssuesIndex(index);
                      setSolutionindex(null);
                      fetchComplainBy_Id(item?._id);
                    }}
                    sx={{
                      height: "7vh",
                      color: "#4D4D4D",
                      fontSize: "18PX",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      fontFamily: "League spartan",
                      fontStyle: "normal",
                      fontWeight: 400,
                      borderBottom: "1px solid #DDDDDD",
                      backgroundColor:
                        issuesIndex === index ? "#EDF0F7" : "white",
                      border: issuesIndex === index ? "1px solid #2D3B67" : "",
                      borderRadius: issuesIndex === index ? "2px" : "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "League spartan",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {" "}
                      {item?.question}
                    </Typography>
                  </Button>
                ))}
              </Box>
            )}
          </Grid>

          <Grid
            xs={5}
            md={5}
            gap={3}
            mb={4}
            sx={{ height: "43vh", scrollbarWidth: "none" }}
          >
            <Grid
              width={"100%"}
              height={"6vh"}
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ borderBottom: "1px solid #DDDDDD" }}
            >
              <Typography
                sx={{
                  padding: "8px",
                  color: "#000",
                  fontFamily: "League spartan",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                }}
              >
                Solution
              </Typography>
              <Box>
                <Button
                  onClick={() => {
                    undoArray[undoArray.length - 1]?.actions?.map((obj2) => {
                      obj2?.arr_no?.map((inx) => {
                        if (
                          undoArray[undoArray.length - 1]?.side ==
                          LISTENING_SIDE.LEFT
                        ) {
                          dispatch(
                            changeByteValue(
                              inx,
                              fittingLeft.data[inx] - obj2.increase_val,
                              undoArray[undoArray.length - 1]?.side
                            )
                          );
                        } else if (
                          undoArray[undoArray.length - 1]?.side ==
                          LISTENING_SIDE.RIGHT
                        ) {
                          dispatch(
                            changeByteValue(
                              inx,
                              fitting.data[inx] - obj2.increase_val,
                              undoArray[undoArray.length - 1]?.side
                            )
                          );
                        } else {
                          dispatch(
                            changeByteValue(
                              inx,
                              fittingLeft.data[inx] - obj2.increase_val,
                              LISTENING_SIDE.LEFT
                            )
                          );
                          dispatch(
                            changeByteValue(
                              inx,
                              fitting.data[inx] - obj2.increase_val,
                              LISTENING_SIDE.RIGHT
                            )
                          );
                        }
                      });
                    });
                    dispatch(
                      callSnackBar(
                        "solution has been undo",
                        SNACK_BAR_VARIETNS.suceess
                      )
                    );
                    undoArray.pop();
                  }}
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    color: "#4D4D4D",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2.66666 4.66667H9.33333C11.5425 4.66667 13.3333 6.45753 13.3333 8.66667C13.3333 10.8758 11.5425 12.6667 9.33333 12.6667H2.66666M2.66666 4.66667L5.33333 2M2.66666 4.66667L5.33333 7.33333"
                      stroke="#4D4D4D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  &nbsp;<Typography>Undo</Typography>{" "}
                </Button>
              </Box>
            </Grid>
            {loadingS ? (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            ) : (
              <Box
                overflow={"auto"}
                sx={{
                  height: "36vh",
                  scrollbarWidth: "none",
                }}
              >
                {solution?.map((sol, index) => (
                  <Box>
                    <Accordion
                      id={index}
                      expanded={solutionindex === index}
                      onChange={() => {
                        if (index == solutionindex) {
                          setSolutionindex(null);
                        } else {
                          setSolutionindex(index);
                        }
                      }}
                    >
                      <AccordionSummary id={index}>
                        <Typography sx={{ paddingLeft: "20px", color: "#666" }}>
                          {sol.solution}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {" "}
                        <Grid p={2}>
                          <RadioGroup
                            onChange={(e) => {
                              setSide(e.target.value);
                            }}
                            value={side}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <Box
                              mb={2}
                              sx={{
                                border: "1px solid #2D3B67",
                                borderRadius: "4px",
                              }}
                              display={"flex"}
                              width="100%"
                              justifyContent={"space-evenly"}
                            >
                              {" "}
                              <Box>
                                <FormControlLabel
                                  value={LISTENING_SIDE.RIGHT}
                                  control={<Radio />}
                                  label={
                                    <Typography variant="subtitle">
                                      Right
                                    </Typography>
                                  }
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  value={LISTENING_SIDE.BOTH}
                                  control={<Radio />}
                                  label={
                                    <Typography variant="subtitle">
                                      Both
                                    </Typography>
                                  }
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  value={LISTENING_SIDE.LEFT}
                                  control={<Radio />}
                                  label={
                                    <Typography variant="subtitle">
                                      Left
                                    </Typography>
                                  }
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                          <SubmitButton
                            loading={applyLoading}
                            onClick={() => {
                              setApplyLoading(true);
                              sol?.actions?.map((val) => {
                                val?.arr_no?.map((arr_index) => {
                                  if (side == LISTENING_SIDE.LEFT) {
                                    dispatch(
                                      changeByteValue(
                                        arr_index,
                                        fittingLeft.data[arr_index] +
                                          val.increase_val,
                                        side
                                      )
                                    );
                                  } else if (side == LISTENING_SIDE.RIGHT) {
                                    dispatch(
                                      changeByteValue(
                                        arr_index,
                                        fitting.data[arr_index] +
                                          val.increase_val,
                                        side
                                      )
                                    );
                                  } else {
                                    dispatch(
                                      changeByteValue(
                                        arr_index,
                                        fittingLeft.data[arr_index] +
                                          val.increase_val,
                                        LISTENING_SIDE.LEFT
                                      )
                                    );
                                    dispatch(
                                      changeByteValue(
                                        arr_index,
                                        fitting.data[arr_index] +
                                          val.increase_val,
                                        LISTENING_SIDE.RIGHT
                                      )
                                    );
                                  }
                                });
                              });
                              setUndoArray((data) => {
                                return [
                                  ...data,
                                  { actions: sol?.actions, side: side },
                                ];
                              });
                              console.log("this is action", sol?.actions);
                              dispatch(
                                callSnackBar(
                                  "solution Applyed",
                                  SNACK_BAR_VARIETNS.suceess
                                )
                              );
                              setApplyLoading(false);
                            }}
                            title={"Apply"}
                          />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </Box>
            )}
            {/* {issuesIndex != null && (
              <Grid>
                <Box
                  p={2}
                  height={"6vh"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  sx={{
                    borderLeft: "1px solid #DDDDDD",
                    borderTop: "1px solid #DDDDDD",
                    borderBottom: "1px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#4D4D4D",
                      fontSize: "18px",
                      fontFamily: "League spartan",
                    }}
                  >
                    Additional Solution
                  </Typography>
                  <IconButton sx={{ borderRadius: "50px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="#4D4D4D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#4D4D4D",
                      fontSize: "18px",
                      fontFamily: "League spartan",
                    }}
                  >
                    {solution[1]?.solution}
                  </Typography>
                </Box>
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ConfigAssistantController;
