import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FittingChartArea from "./FittingChartArea";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import MenuItemgraphImage from "../../assets/images/MenuItemgraphImage.svg";
import {
  changeByteValue,
  changeDeviceSideSelection,
} from "../../store/actions/fittingAction";
import { ratios } from "../../utils/ratios";
import { roundValueForCRAndER } from "../../utils/helper";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FineTunningController = ({
  setData8L,
  data8L,
  setData8R,
  data8R,
  mutedHandle,
  setMPOL32,
  setMPOR32,
  mpoR32,
  mpoL32,
  setFreShapR32,
  freShapR32,
  setFreShapL32,
  freShapL32,
  setShouldUpdate,
}) => {
  const [edgeVal, setEdgeVal] = useState(50);
  const { fittingLeft } = useSelector((state) => state);
  const { fitting } = useSelector((state) => state);
  const [value, setValue] = useState(0);
  const [Left, setLeft] = useState(
    fitting.connected ? LISTENING_SIDE.RIGHT : LISTENING_SIDE.LEFT
  );
  const [link, setLink] = useState(false);
  const [sliderButton, setSliderButton] = useState(
    fitting.read_only || fittingLeft.read_only ? null : 0
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setShouldUpdate(true);
    };
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onHandleChange = (index, e) => {
    if (value == 1) {
      if (Left == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          setData8L((arr) => {
            arr[4][index - 60 + 1] = e.target.value;
            return arr;
          });
          dispatch(changeByteValue(index, e.target.value, Left));
        } else {
          let mainIndex = Math.ceil(index / 4);
          setMPOL32((arr) => {
            arr[index] = e.target.value;
            return arr;
          });
          let data = e.target.value;
          for (let i = mainIndex * 4 - 3; i <= mainIndex * 4; i++) {
            if (i != index) {
              if (i < 0) {
                data += mpoL32[0];
              } else {
                data += mpoL32[i];
              }
            }
          }
          dispatch(
            changeByteValue(
              mainIndex + 60,
              Math.round(data / 4),
              LISTENING_SIDE.LEFT
            )
          );
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          setData8R((arr) => {
            arr[4][index - 60 + 1] = e.target.value;
            return arr;
          });
          dispatch(changeByteValue(index, e.target.value, Left));
        } else {
          let mainIndex = Math.ceil(index / 4);
          setMPOR32((arr) => {
            arr[index] = e.target.value;
            return arr;
          });
          let data = e.target.value;
          for (let i = mainIndex * 4 - 3; i <= mainIndex * 4; i++) {
            if (i != index) {
              if (i < 0) {
                data += mpoR32[0];
              } else {
                data += mpoR32[i];
              }
            }
          }
          dispatch(
            changeByteValue(
              mainIndex + 60,
              Math.round(data / 4),
              LISTENING_SIDE.RIGHT
            )
          );
        }
      }
    } else {
      let ER = 20;
      let EK = 28;
      let LG = 36;
      let CK = 44;
      let CR = 52;
      let LK = 60;
      if (Left == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          let tempindex = index - 60 + 1;
          setData8L((arr) => {
            let prev = arr[1][tempindex];
            arr[1][tempindex] = e.target.value;
            const cal = ratios(
              arr[1][tempindex],
              arr[2][tempindex],
              arr[3][tempindex],
              arr[4][tempindex]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + tempindex - 1,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  EK + tempindex - 1,
                  cal.Eknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(LG + tempindex - 1, cal.LG, LISTENING_SIDE.LEFT)
              );
              dispatch(
                changeByteValue(
                  CK + tempindex - 1,
                  cal.Cknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CR + tempindex - 1,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  LK + tempindex - 1,
                  cal.MPO,
                  LISTENING_SIDE.LEFT
                )
              );
            } else {
              arr[1][tempindex] = prev;
            }
            return arr;
          });
        } else {
          let mainIndex = Math.ceil(index / 4);
          if (mainIndex == 0) {
            setFreShapL32((arr) => {
              const prev = arr[0][0];
              arr[0][0] = e.target.value;
              let cal = ratios(
                arr[0][0],
                freShapL32[1][0],
                freShapL32[2][0],
                mpoL32[0]
              );
              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(changeByteValue(EK, cal.Eknee, LISTENING_SIDE.LEFT));
                dispatch(changeByteValue(LG, cal.LG, LISTENING_SIDE.LEFT));
                dispatch(changeByteValue(CK, cal.Cknee, LISTENING_SIDE.LEFT));
                dispatch(
                  changeByteValue(
                    CR,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(LK, Math.round(cal.MPO), LISTENING_SIDE.LEFT)
                );
              } else {
                arr[0][0] = prev;
              }
              return arr;
            });
          } else {
            setFreShapL32((arr) => {
              const prev = arr[0][index];
              arr[0][index] = e.target.value;

              let G50 = 0;
              let G65 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = mainIndex * 4 - 3; i <= mainIndex * 4; i++) {
                G50 += arr[0][i];
                G65 += freShapL32[1][i];
                G80 += freShapL32[2][i];
                MPO += mpoL32[i];
              }
              let cal = ratios(
                Math.round(G50 / 4),
                Math.round(G65 / 4),
                Math.round(G80 / 4),
                Math.round(MPO / 4)
              );

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + mainIndex,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + mainIndex,
                    cal.Eknee,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(LG + mainIndex, cal.LG, LISTENING_SIDE.LEFT)
                );
                dispatch(
                  changeByteValue(
                    CK + mainIndex,
                    cal.Cknee,
                    LISTENING_SIDE.LEFT
                  )
                );

                dispatch(
                  changeByteValue(
                    CR + mainIndex,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    LK + mainIndex,
                    Math.round(cal.MPO),
                    LISTENING_SIDE.LEFT
                  )
                );
              } else {
                arr[0][index] = prev;
              }
              return arr;
            });
          }
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          let tempindex = index - 60 + 1;
          setData8R((arr) => {
            const prev = arr[1][tempindex];
            arr[1][tempindex] = e.target.value;

            const cal = ratios(
              arr[1][tempindex],
              arr[2][tempindex],
              arr[3][tempindex],
              arr[4][tempindex]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + tempindex - 1,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  EK + tempindex - 1,
                  cal.Eknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  LG + tempindex - 1,
                  cal.LG,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CK + tempindex - 1,
                  cal.Cknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CR + tempindex - 1,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  LK + tempindex - 1,
                  cal.MPO,
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              arr[1][tempindex] = prev;
            }

            return arr;
          });
        } else {
          let mainIndex = Math.ceil(index / 4);
          if (mainIndex == 0) {
            setFreShapR32((arr) => {
              const prev = arr[0][0];
              arr[0][0] = e.target.value;

              let cal = ratios(
                arr[0][0],
                freShapR32[1][0],
                freShapR32[2][0],
                mpoR32[0]
              );
              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(changeByteValue(EK, cal.Eknee, LISTENING_SIDE.RIGHT));
                dispatch(changeByteValue(LG, cal.LG, LISTENING_SIDE.RIGHT));
                dispatch(changeByteValue(CK, cal.Cknee, LISTENING_SIDE.RIGHT));
                dispatch(
                  changeByteValue(
                    CR,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(LK, Math.round(cal.MPO), LISTENING_SIDE.RIGHT)
                );
              } else {
                arr[0][0] = prev;
              }
              return arr;
            });
          } else {
            setFreShapR32((arr) => {
              const prev = arr[0][index];
              arr[0][index] = e.target.value;

              let G50 = 0;
              let G65 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = mainIndex * 4 - 3; i <= mainIndex * 4; i++) {
                G50 += arr[0][i];
                G65 += freShapR32[1][i];
                G80 += freShapR32[2][i];
                MPO += mpoR32[i];
              }
              let cal = ratios(
                Math.round(G50 / 4),
                Math.round(G65 / 4),
                Math.round(G80 / 4),
                Math.round(MPO / 4)
              );

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + mainIndex,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + mainIndex,
                    cal.Eknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(LG + mainIndex, cal.LG, LISTENING_SIDE.RIGHT)
                );
                dispatch(
                  changeByteValue(
                    CK + mainIndex,
                    cal.Cknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    CR + mainIndex,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );

                dispatch(
                  changeByteValue(
                    LK + mainIndex,
                    Math.round(cal.MPO),
                    LISTENING_SIDE.RIGHT
                  )
                );
              } else {
                arr[0][index] = prev;
              }
              return arr;
            });
          }
        }
      }
    }
    // Fre Shaping
  };

  const onIncreament = (side) => {
    let ER = 20;
    let EK = 28;
    let LG = 36;
    let CK = 44;
    let CR = 52;
    let LK = 60;
    // MPO
    if (value == 1) {
      if (side == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          setData8L((prev) => {
            const arr = [...prev];
            if (arr[4][sliderButton + 1] < 130) arr[4][sliderButton + 1] += 1;

            dispatch(
              changeByteValue(60 + sliderButton, arr[4][sliderButton + 1], side)
            );
            return arr;
          });
        } else {
          setMPOL32((prev) => {
            const arr = [...prev];
            if (arr[sliderButton] < 130) arr[sliderButton] += 1;
            if (arr[sliderButton + 1] < 130) arr[sliderButton + 1] += 1;
            if (arr[sliderButton + 2] < 130) arr[sliderButton + 2] += 1;
            if (arr[sliderButton + 3] < 130) arr[sliderButton + 3] += 1;

            if (sliderButton == 0) {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  arr[sliderButton],
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60 + 1,
                  Math.round(
                    (arr[sliderButton + 1] +
                      arr[sliderButton + 2] +
                      arr[sliderButton + 3] +
                      mpoL32[sliderButton + 4]) /
                      4
                  ),
                  LISTENING_SIDE.LEFT
                )
              );
            } else {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  Math.round(
                    (arr[sliderButton - 1] +
                      arr[sliderButton] +
                      arr[sliderButton - 2] +
                      arr[sliderButton - 3]) /
                      4
                  ),
                  LISTENING_SIDE.LEFT
                )
              );
              if (sliderButton < 28) {
                dispatch(
                  changeByteValue(
                    sliderButton / 4 + 60 + 1,
                    Math.round(
                      (arr[sliderButton + 1] +
                        arr[sliderButton + 2] +
                        arr[sliderButton + 3] +
                        arr[sliderButton + 4]) /
                        4
                    ),
                    LISTENING_SIDE.LEFT
                  )
                );
              }
            }
            return arr;
          });
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          setData8R((prev) => {
            const arr = [...prev];
            if (arr[4][sliderButton + 1] < 130) arr[4][sliderButton + 1] += 1;

            dispatch(
              changeByteValue(60 + sliderButton, arr[4][sliderButton + 1], side)
            );
            return arr;
          });
        } else {
          setMPOR32((prev) => {
            const arr = [...prev];
            if (arr[sliderButton] < 130) arr[sliderButton] += 1;
            if (arr[sliderButton + 1] < 130) arr[sliderButton + 1] += 1;
            if (arr[sliderButton + 2] < 130) arr[sliderButton + 2] += 1;
            if (arr[sliderButton + 3] < 130) arr[sliderButton + 3] += 1;

            if (sliderButton == 0) {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  arr[sliderButton],
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60 + 1,
                  Math.round(
                    (arr[sliderButton + 1] +
                      arr[sliderButton + 2] +
                      arr[sliderButton + 3] +
                      arr[sliderButton + 4]) /
                      4
                  ),
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  Math.round(
                    (arr[sliderButton - 1] +
                      arr[sliderButton] +
                      arr[sliderButton - 2] +
                      arr[sliderButton - 3]) /
                      4
                  ),
                  LISTENING_SIDE.RIGHT
                )
              );
              if (sliderButton < 28) {
                dispatch(
                  changeByteValue(
                    sliderButton / 4 + 60 + 1,
                    Math.round(
                      (arr[sliderButton + 1] +
                        arr[sliderButton + 2] +
                        arr[sliderButton + 3] +
                        arr[sliderButton + 4]) /
                        4
                    ),
                    LISTENING_SIDE.RIGHT
                  )
                );
              }
            }
            return arr;
          });
        }
      }
    } else {
      if (side == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          setData8L((prev) => {
            const arr = [...prev];
            if (arr[1][sliderButton + 1] < 50) arr[1][sliderButton + 1] += 1;

            const cal = ratios(
              arr[1][sliderButton + 1],
              data8L[2][sliderButton + 1],
              data8L[3][sliderButton + 1],
              data8L[4][sliderButton + 1]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton,
                  cal.Eknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(LG + sliderButton, cal.LG, LISTENING_SIDE.LEFT)
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton,
                  cal.Cknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(LK + sliderButton, cal.MPO, LISTENING_SIDE.LEFT)
              );
            } else {
              arr[1][sliderButton + 1] -= 1;
            }
            return arr;
          });
        } else {
          setFreShapL32((prev) => {
            const arr = [...prev];
            const unChangeArr = prev.map((row) => [...row]);
            for (let i = sliderButton; i <= sliderButton + 3; i++) {
              if (arr[0][i] < 50) {
                arr[0][i] += 1;
              }
            }

            let G50 = 0;
            let G60 = 0;
            let G80 = 0;
            let MPO = 0;

            if (sliderButton < 28) {
              for (let i = sliderButton + 1; i <= sliderButton + 4; i++) {
                G50 += arr[0][i];
                G60 += freShapL32[1][i];
                G80 += freShapL32[2][i];
                MPO += mpoL32[i];
              }

              let cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + sliderButton / 4 + 1,
                    cal.Eknee,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    LG + sliderButton / 4 + 1,
                    cal.LG,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    CK + sliderButton / 4 + 1,
                    cal.Cknee,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    CR + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );

                dispatch(
                  changeByteValue(
                    LK + sliderButton / 4 + 1,
                    cal.MPO,
                    LISTENING_SIDE.LEFT
                  )
                );
              } else {
                for (let i = sliderButton + 1; i <= sliderButton + 3; i++) {
                  if (arr[0][i] != unChangeArr[0][i]) {
                    arr[0][i] -= 1;
                  }
                }
              }
            }

            G50 = 0;
            G60 = 0;
            G80 = 0;
            MPO = 0;

            let cal = 0;

            if (sliderButton == 0) {
              cal = ratios(arr[0][0], arr[1][0], arr[2][0], mpoL32[0]);
            } else {
              for (let i = sliderButton; i > sliderButton - 4; i--) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoL32[i];
              }
              cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);
            }

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton / 4,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton / 4,
                  cal.Eknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  LG + sliderButton / 4,
                  cal.LG,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton / 4,
                  cal.Cknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton / 4,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  LK + sliderButton / 4,
                  Math.round(cal.MPO),
                  LISTENING_SIDE.LEFT
                )
              );
            } else {
              if (arr[0][sliderButton] != unChangeArr[0][sliderButton]) {
                arr[0][sliderButton] -= 1;
              }
            }
            return arr;
          });
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          setData8R((prev) => {
            const arr = [...prev];
            if (arr[1][sliderButton + 1] < 50) arr[1][sliderButton + 1] += 1;

            const cal = ratios(
              arr[1][sliderButton + 1],
              data8R[2][sliderButton + 1],
              data8R[3][sliderButton + 1],
              data8R[4][sliderButton + 1]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton,
                  cal.Eknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(LG + sliderButton, cal.LG, LISTENING_SIDE.RIGHT)
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton,
                  cal.Cknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  LK + sliderButton,
                  cal.MPO,
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              arr[1][sliderButton + 1] -= 1;
            }

            return arr;
          });
        } else {
          setFreShapR32((prev) => {
            const arr = [...prev];
            const unChangeArr = prev.map((row) => [...row]);
            for (let i = sliderButton; i <= sliderButton + 3; i++) {
              if (arr[0][i] < 50) {
                arr[0][i] += 1;
              }
            }

            let G50 = 0;
            let G60 = 0;
            let G80 = 0;
            let MPO = 0;

            if (sliderButton < 28) {
              for (let i = sliderButton + 1; i <= sliderButton + 4; i++) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoR32[i];
              }
              let cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + sliderButton / 4 + 1,
                    cal.Eknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    LG + sliderButton / 4 + 1,
                    cal.LG,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    CK + sliderButton / 4 + 1,
                    cal.Cknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    CR + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );

                dispatch(
                  changeByteValue(
                    LK + sliderButton / 4 + 1,
                    Math.round(cal.MPO),
                    LISTENING_SIDE.RIGHT
                  )
                );
              } else {
                for (let i = sliderButton + 1; i <= sliderButton + 3; i++) {
                  if (arr[0][i] != unChangeArr[0][i]) {
                    arr[0][i] -= 1;
                  }
                }
              }
            }

            G50 = 0;
            G60 = 0;
            G80 = 0;
            MPO = 0;

            let cal = 0;

            if (sliderButton == 0) {
              cal = ratios(arr[0][0], arr[1][0], arr[2][0], mpoR32[0]);
            } else {
              for (let i = sliderButton; i > sliderButton - 4; i--) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoR32[i];
              }
              cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);
            }

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton / 4,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton / 4,
                  cal.Eknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  LG + sliderButton / 4,
                  cal.LG,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton / 4,
                  cal.Cknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton / 4,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );

              dispatch(
                changeByteValue(
                  LK + sliderButton / 4,
                  Math.round(cal.MPO),
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              if (arr[0][sliderButton] != unChangeArr[0][sliderButton]) {
                arr[0][sliderButton] -= 1;
              }
            }
            return arr;
          });
        }
      }
    }
  };

  const onDecreament = (side) => {
    let ER = 20;
    let EK = 28;
    let LG = 36;
    let CK = 44;
    let CR = 52;
    let LK = 60;
    // MPO
    if (value == 1) {
      if (side == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          setData8L((prev) => {
            const arr = [...prev];
            if (arr[4][sliderButton + 1] > 70) arr[4][sliderButton + 1] -= 1;

            dispatch(
              changeByteValue(60 + sliderButton, arr[4][sliderButton + 1], side)
            );
            return arr;
          });
        } else {
          setMPOL32((prev) => {
            const arr = [...prev];
            if (arr[sliderButton] > 0) arr[sliderButton] -= 1;
            if (arr[sliderButton + 1] > 0) arr[sliderButton + 1] -= 1;
            if (arr[sliderButton + 2] > 0) arr[sliderButton + 2] -= 1;
            if (arr[sliderButton + 3] > 0) arr[sliderButton + 3] -= 1;

            if (sliderButton == 0) {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  arr[sliderButton],
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60 + 1,
                  Math.round(
                    (arr[sliderButton + 1] +
                      arr[sliderButton + 2] +
                      arr[sliderButton + 3] +
                      arr[sliderButton + 4]) /
                      4
                  ),
                  LISTENING_SIDE.LEFT
                )
              );
            } else {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  Math.round(
                    (arr[sliderButton - 1] +
                      arr[sliderButton] +
                      arr[sliderButton - 2] +
                      arr[sliderButton - 3]) /
                      4
                  ),
                  LISTENING_SIDE.LEFT
                )
              );

              if (sliderButton < 28) {
                dispatch(
                  changeByteValue(
                    sliderButton / 4 + 60 + 1,
                    Math.round(
                      (arr[sliderButton + 1] +
                        arr[sliderButton + 2] +
                        arr[sliderButton + 3] +
                        arr[sliderButton + 4]) /
                        4
                    ),
                    LISTENING_SIDE.LEFT
                  )
                );
              }
            }
            return arr;
          });
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          setData8R((prev) => {
            const arr = [...prev];
            if (arr[4][sliderButton + 1] > 70) arr[4][sliderButton + 1] -= 1;

            dispatch(
              changeByteValue(60 + sliderButton, arr[4][sliderButton + 1], side)
            );
            return arr;
          });
        } else {
          setMPOR32((prev) => {
            const arr = [...prev];
            if (arr[sliderButton] > 0) arr[sliderButton] -= 1;
            if (arr[sliderButton + 1] > 0) arr[sliderButton + 1] -= 1;
            if (arr[sliderButton + 2] > 0) arr[sliderButton + 2] -= 1;
            if (arr[sliderButton + 3] > 0) arr[sliderButton + 3] -= 1;

            if (sliderButton == 0) {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  arr[sliderButton],
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60 + 1,
                  Math.round(
                    (arr[sliderButton + 1] +
                      arr[sliderButton + 2] +
                      arr[sliderButton + 3] +
                      arr[sliderButton + 4]) /
                      4
                  ),
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              dispatch(
                changeByteValue(
                  sliderButton / 4 + 60,
                  Math.round(
                    (arr[sliderButton - 1] +
                      arr[sliderButton] +
                      arr[sliderButton - 2] +
                      arr[sliderButton - 3]) /
                      4
                  ),
                  LISTENING_SIDE.RIGHT
                )
              );
              if (sliderButton < 28) {
                dispatch(
                  changeByteValue(
                    sliderButton / 4 + 60 + 1,
                    Math.round(
                      (arr[sliderButton + 1] +
                        arr[sliderButton + 2] +
                        arr[sliderButton + 3] +
                        arr[sliderButton + 4]) /
                        4
                    ),
                    LISTENING_SIDE.RIGHT
                  )
                );
              }
            }
            return arr;
          });
        }
      }
    } else {
      if (side == LISTENING_SIDE.LEFT) {
        if (fittingLeft.device_type == DEVICES.BTE_OPTIMA) {
          setData8L((prev) => {
            const arr = [...prev];
            if (arr[1][sliderButton + 1] > 0) arr[1][sliderButton + 1] -= 1;

            const cal = ratios(
              arr[1][sliderButton + 1],
              data8L[2][sliderButton + 1],
              data8L[3][sliderButton + 1],
              data8L[4][sliderButton + 1]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton,
                  cal.Eknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(LG + sliderButton, cal.LG, LISTENING_SIDE.LEFT)
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton,
                  cal.Cknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(LK + sliderButton, cal.MPO, LISTENING_SIDE.LEFT)
              );
            } else {
              arr[1][sliderButton + 1] += 1;
            }

            return arr;
          });
        } else {
          setFreShapL32((prev) => {
            const arr = [...prev];
            const unChangeArr = prev.map((row) => [...row]);
            for (let i = sliderButton; i <= sliderButton + 3; i++) {
              if (arr[0][i] > 0) {
                arr[0][i] -= 1;
              }
            }

            let G50 = 0;
            let G60 = 0;
            let G80 = 0;
            let MPO = 0;

            if (sliderButton < 28) {
              for (let i = sliderButton + 1; i <= sliderButton + 4; i++) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoL32[i];
              }
              let cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + sliderButton / 4 + 1,
                    cal.Eknee,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    LG + sliderButton / 4 + 1,
                    cal.LG,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    CK + sliderButton / 4 + 1,
                    cal.Cknee,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    CR + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.LEFT
                  )
                );
                dispatch(
                  changeByteValue(
                    LK + sliderButton / 4 + 1,
                    Math.round(cal.MPO),
                    LISTENING_SIDE.LEFT
                  )
                );
              } else {
                for (let i = sliderButton + 1; i <= sliderButton + 3; i++) {
                  if (arr[0][i] != unChangeArr[0][i]) {
                    arr[0][i] += 1;
                  }
                }
              }
            }

            G50 = 0;
            G60 = 0;
            G80 = 0;
            MPO = 0;

            let cal = 0;

            if (sliderButton == 0) {
              cal = ratios(arr[0][0], arr[1][0], arr[2][0], mpoL32[0]);
            } else {
              for (let i = sliderButton; i > sliderButton - 4; i--) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoL32[i];
              }
              cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);
            }

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton / 4,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton / 4,
                  cal.Eknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  LG + sliderButton / 4,
                  cal.LG,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton / 4,
                  cal.Cknee,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton / 4,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.LEFT
                )
              );
              dispatch(
                changeByteValue(
                  LK + sliderButton / 4,
                  Math.round(cal.MPO),
                  LISTENING_SIDE.LEFT
                )
              );
            } else {
              if (arr[0][sliderButton] != unChangeArr[0][sliderButton]) {
                arr[0][sliderButton] += 1;
              }
            }

            return arr;
          });
        }
      } else {
        if (fitting.device_type == DEVICES.BTE_OPTIMA) {
          setData8R((prev) => {
            const arr = [...prev];
            if (arr[1][sliderButton + 1] > 0) arr[1][sliderButton + 1] -= 1;

            const cal = ratios(
              arr[1][sliderButton + 1],
              data8R[2][sliderButton + 1],
              data8R[3][sliderButton + 1],
              data8R[4][sliderButton + 1]
            );

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton,
                  cal.Eknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(LG + sliderButton, cal.LG, LISTENING_SIDE.RIGHT)
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton,
                  cal.Cknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );

              dispatch(
                changeByteValue(
                  LK + sliderButton,
                  cal.MPO,
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              arr[1][sliderButton + 1] += 1;
            }

            return arr;
          });
        } else {
          setFreShapR32((prev) => {
            const arr = [...prev];
            const unChangeArr = prev.map((row) => [...row]);
            for (let i = sliderButton; i <= sliderButton + 3; i++) {
              if (arr[0][i] > 0) {
                arr[0][i] -= 1;
              }
            }

            let G50 = 0;
            let G60 = 0;
            let G80 = 0;
            let MPO = 0;

            if (sliderButton < 28) {
              for (let i = sliderButton + 1; i <= sliderButton + 4; i++) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoR32[i];
              }
              let cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);

              if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(
                    ER + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.ER) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    EK + sliderButton / 4 + 1,
                    cal.Eknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    LG + sliderButton / 4 + 1,
                    cal.LG,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    CK + sliderButton / 4 + 1,
                    cal.Cknee,
                    LISTENING_SIDE.RIGHT
                  )
                );
                dispatch(
                  changeByteValue(
                    CR + sliderButton / 4 + 1,
                    roundValueForCRAndER(cal.CR) * 10,
                    LISTENING_SIDE.RIGHT
                  )
                );

                dispatch(
                  changeByteValue(
                    LK + sliderButton / 4 + 1,
                    Math.round(cal.MPO),
                    LISTENING_SIDE.RIGHT
                  )
                );
              } else {
                for (let i = sliderButton + 1; i <= sliderButton + 3; i++) {
                  if (arr[0][i] != unChangeArr[0][i]) {
                    arr[0][i] += 1;
                  }
                }
              }
            }

            G50 = 0;
            G60 = 0;
            G80 = 0;
            MPO = 0;

            let cal = 0;

            if (sliderButton == 0) {
              cal = ratios(arr[0][0], arr[1][0], arr[2][0], mpoR32[0]);
            } else {
              for (let i = sliderButton; i > sliderButton - 4; i--) {
                G50 += arr[0][i];
                G60 += arr[1][i];
                G80 += arr[2][i];
                MPO += mpoR32[i];
              }
              cal = ratios(G50 / 4, G60 / 4, G80 / 4, MPO / 4);
            }

            if (cal.ER > 0 && cal.ER <= 2 && cal.CR >= 1 && cal.CR <= 3) {
              dispatch(
                changeByteValue(
                  ER + sliderButton / 4,
                  roundValueForCRAndER(cal.ER) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  EK + sliderButton / 4,
                  cal.Eknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  LG + sliderButton / 4,
                  cal.LG,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CK + sliderButton / 4,
                  cal.Cknee,
                  LISTENING_SIDE.RIGHT
                )
              );
              dispatch(
                changeByteValue(
                  CR + sliderButton / 4,
                  roundValueForCRAndER(cal.CR) * 10,
                  LISTENING_SIDE.RIGHT
                )
              );

              dispatch(
                changeByteValue(
                  LK + sliderButton / 4,
                  Math.round(cal.MPO),
                  LISTENING_SIDE.RIGHT
                )
              );
            } else {
              if (arr[0][sliderButton] != unChangeArr[0][sliderButton]) {
                arr[0][sliderButton] += 1;
              }
            }

            return arr;
          });
        }
      }
    }
    // Fre Shaping
  };

  return (
    <>
      <Box>
        <Box maxHeight={"60vh"}>
          <Grid xs={12} md={12} container>
            <Grid md={5.7} xs={5.7}>
              <FittingChartArea
                mutedHandle={mutedHandle}
                fitting={fitting}
                edgeVal={edgeVal}
              />
            </Grid>
            <Grid md={0.6} xs={0.6}>
              <Box
                sx={{
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                    <MenuItem value={10}>
                      <img src={MenuItemgraphImage} alt="SPEAKERIMAGE" />
                    </MenuItem>
                  </Select>
                </Box> */}
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#4A4A4A",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        50dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#969696",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        65dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#CCCCCC",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        80dB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={5.7} md={5.7}>
              <FittingChartArea
                mutedHandle={mutedHandle}
                fitting={fittingLeft}
                edgeVal={edgeVal}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid xs={12} display={"flex"} justifyContent={"center"} width={"60vw"}>
        <Grid
          xs={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box>
            <Tabs value={value} sx={{ height: "1vh" }}>
              <Tab
                onClick={() => {
                  handleChange(0);
                }}
                value={0}
                sx={{ minHeight: "5px" }}
                label={<Typography variant="h6">Frequency shaping</Typography>}
                {...a11yProps(0)}
              />
              <Tab
                onClick={() => {
                  handleChange(1);
                }}
                value={1}
                label={<Typography variant="h6">MPO</Typography>}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Grid>
        {fitting.connected &&
          fittingLeft.connected &&
          fitting.device_type == fittingLeft.device_type && (
            <Grid
              xs={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {!link ? (
                <Button
                  sx={{ border: "1px solid #DDDDDD", borderRadius: "8px" }}
                  onClick={() => {
                    setLink(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="24"
                    viewBox="0 0 33 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                      stroke="#CCCCCC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setLink(false);
                  }}
                  sx={{
                    border: "1px solid #2D3B67",
                    borderRadius: "8px",
                    backgroundColor: "#EDF0F7",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="24"
                    viewBox="0 0 32 24"
                    fill="none"
                  >
                    <path
                      d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                      stroke="#2D3B67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              )}
            </Grid>
          )}

        {/* <Grid xs={5} width={"100%"} display={"flex"} alignItems={"center"} flexDirection={"row"}> */}
        {/* <Box height={"2px"}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab label={<Typography variant="h6" fontSize="14px"  lineHeight="0px" padding={"0px"}>
                Frequency shaping
              </Typography>}  {...a11yProps(0)} />
              <Tab label={<Typography variant="h6" fontSize="14px" height="1vh" lineHeight="0px" padding={"0px"}>
                MPO
              </Typography>} {...a11yProps(1)} />

            </Tabs>
          </Box> */}
        {/* </Grid> */}
      </Grid>

      <Box
        // ml={5}
        mt={1}
        sx={{
          height: "36.5vh",
          scrollbarWidth: "none",
          width: "86vw",
          // overflow:"auto"
        }}
      >
        <Grid
          container
          xs={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            onClick={() => {
              setLeft(LISTENING_SIDE.RIGHT);
            }}
            sx={{
              visibility:
                Left == LISTENING_SIDE.RIGHT || !fitting.connected
                  ? "hidden"
                  : "",
              cursor: "pointer",
              backgroundColor: "#C24747",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }} > */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 17.5L1.5 9.5L9.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>

          {((Left == LISTENING_SIDE.LEFT &&
            fittingLeft.device_type == DEVICES.BTE_PRIME) ||
            (Left == LISTENING_SIDE.RIGHT &&
              fitting.device_type == DEVICES.BTE_PRIME)) && (
            <>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(0);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 0
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 0
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[0] + mpoL32[1] + mpoL32[2] + mpoL32[3]) / 4
                          )
                        : Math.round(
                            (mpoR32[0] + mpoR32[1] + mpoR32[2] + mpoR32[3]) / 4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][0] +
                            freShapL32[0][1] +
                            freShapL32[0][2] +
                            freShapL32[0][3]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][0] +
                            freShapR32[0][1] +
                            freShapR32[0][2] +
                            freShapR32[0][3]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 0
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    // border: "1px solid #DDDDDD",
                    // borderRight: "1px solid #C24747",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(0, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[0]
                          : mpoR32[0]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][0]
                        : freShapR32[0][0]
                    }
                    // color='#EDF0F7'
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(1, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    valueLabelDisplay="auto"
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[1]
                          : mpoR32[1]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][1]
                        : freShapR32[0][1]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      !link && onHandleChange(2, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[2]
                          : mpoR32[2]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][2]
                        : freShapR32[0][2]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    min={value == 0 ? 0 : 70}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      !link && onHandleChange(3, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[3]
                          : mpoR32[3]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][3]
                        : freShapR32[0][3]
                    }
                  />
                </Grid>
                <Typography>0</Typography>
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(4);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 4
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 4
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[4] + mpoL32[5] + mpoL32[6] + mpoL32[7]) / 4
                          )
                        : Math.round(
                            (mpoR32[4] + mpoR32[5] + mpoR32[6] + mpoR32[7]) / 4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][4] +
                            freShapL32[0][5] +
                            freShapL32[0][6] +
                            freShapL32[0][7]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][4] +
                            freShapR32[0][5] +
                            freShapR32[0][6] +
                            freShapR32[0][7]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    border: "1px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 4
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(4, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[4]
                          : mpoR32[4]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][4]
                        : freShapR32[0][4]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(5, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[5]
                          : mpoR32[5]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][5]
                        : freShapR32[0][5]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(6, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[6]
                          : mpoR32[6]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][6]
                        : freShapR32[0][6]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(7, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[7]
                          : mpoR32[7]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][7]
                        : freShapR32[0][7]
                    }
                  />
                </Grid>
                <Typography>0.4</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(8);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 8
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 8
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 8
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 8
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[8] + mpoL32[9] + mpoL32[10] + mpoL32[11]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[8] + mpoR32[9] + mpoR32[10] + mpoR32[11]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][8] +
                            freShapL32[0][9] +
                            freShapL32[0][10] +
                            freShapL32[0][11]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][8] +
                            freShapR32[0][9] +
                            freShapR32[0][10] +
                            freShapR32[0][11]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 8
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 8
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(8, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[8]
                          : mpoR32[8]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][8]
                        : freShapR32[0][8]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(9, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[9]
                          : mpoR32[9]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][9]
                        : freShapR32[0][9]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(10, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[10]
                          : mpoR32[10]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][10]
                        : freShapR32[0][10]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(11, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[11]
                          : mpoR32[11]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][11]
                        : freShapR32[0][11]
                    }
                  />
                </Grid>
                <Typography>0.7</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(12);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 12
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 12
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 12
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 12
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[12] +
                              mpoL32[13] +
                              mpoL32[14] +
                              mpoL32[15]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[12] +
                              mpoR32[13] +
                              mpoR32[14] +
                              mpoR32[15]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][12] +
                            freShapL32[0][13] +
                            freShapL32[0][14] +
                            freShapL32[0][15]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][12] +
                            freShapR32[0][13] +
                            freShapR32[0][14] +
                            freShapR32[0][15]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 12
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 12
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(12, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[12]
                          : mpoR32[12]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][12]
                        : freShapR32[0][12]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(13, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[13]
                          : mpoR32[13]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][13]
                        : freShapR32[0][13]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(14, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[14]
                          : mpoR32[14]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][14]
                        : freShapR32[0][14]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(15, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[15]
                          : mpoR32[15]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][15]
                        : freShapR32[0][15]
                    }
                  />
                </Grid>
                <Typography>1.1</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(16);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    borderTop: "8px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 16
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 16
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 16
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 16
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[16] +
                              mpoL32[17] +
                              mpoL32[18] +
                              mpoL32[19]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[16] +
                              mpoR32[17] +
                              mpoR32[18] +
                              mpoR32[19]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][16] +
                            freShapL32[0][17] +
                            freShapL32[0][18] +
                            freShapL32[0][19]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][16] +
                            freShapR32[0][17] +
                            freShapR32[0][18] +
                            freShapR32[0][19]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 16
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 16
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(16, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[16]
                          : mpoR32[16]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][16]
                        : freShapR32[0][16]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(17, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[17]
                          : mpoR32[17]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][17]
                        : freShapR32[0][17]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(18, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[18]
                          : mpoR32[18]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][18]
                        : freShapR32[0][18]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(19, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[19]
                          : mpoR32[19]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][19]
                        : freShapR32[0][19]
                    }
                  />
                </Grid>
                <Typography>1.8</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(20);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 20
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 20
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 20
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 20
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[20] +
                              mpoL32[21] +
                              mpoL32[22] +
                              mpoL32[23]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[20] +
                              mpoR32[21] +
                              mpoR32[22] +
                              mpoR32[23]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][20] +
                            freShapL32[0][21] +
                            freShapL32[0][22] +
                            freShapL32[0][23]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][20] +
                            freShapR32[0][21] +
                            freShapR32[0][22] +
                            freShapR32[0][23]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    border: "1px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 20
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 20
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(20, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[20]
                          : mpoR32[20]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][20]
                        : freShapR32[0][20]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(21, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[21]
                          : mpoR32[21]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][21]
                        : freShapR32[0][21]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(22, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[22]
                          : mpoR32[22]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][22]
                        : freShapR32[0][22]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(23, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[23]
                          : mpoR32[23]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][23]
                        : freShapR32[0][23]
                    }
                  />
                </Grid>
                <Typography>3k</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(24);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 24
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 24
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 24
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 24
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[24] +
                              mpoL32[25] +
                              mpoL32[26] +
                              mpoL32[27]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[24] +
                              mpoR32[25] +
                              mpoR32[26] +
                              mpoR32[27]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][24] +
                            freShapL32[0][25] +
                            freShapL32[0][26] +
                            freShapL32[0][27]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][24] +
                            freShapR32[0][25] +
                            freShapR32[0][26] +
                            freShapR32[0][27]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 24
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 24
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(24, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[24]
                          : mpoR32[24]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][24]
                        : freShapR32[0][24]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(25, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[25]
                          : mpoR32[25]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][25]
                        : freShapR32[0][25]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(26, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[26]
                          : mpoR32[26]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][26]
                        : freShapR32[0][26]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(27, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[27]
                          : mpoR32[27]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][27]
                        : freShapR32[0][27]
                    }
                  />
                </Grid>
                <Typography>5k</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(28);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.26vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 28
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 28
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 28
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 28
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? Math.round(
                            (mpoL32[28] +
                              mpoL32[29] +
                              mpoL32[30] +
                              mpoL32[31]) /
                              4
                          )
                        : Math.round(
                            (mpoR32[28] +
                              mpoR32[29] +
                              mpoR32[30] +
                              mpoR32[31]) /
                              4
                          )
                      : Left == LISTENING_SIDE.LEFT
                      ? Math.round(
                          (freShapL32[0][28] +
                            freShapL32[0][29] +
                            freShapL32[0][30] +
                            freShapL32[0][31]) /
                            4
                        )
                      : Math.round(
                          (freShapR32[0][28] +
                            freShapR32[0][29] +
                            freShapR32[0][30] +
                            freShapR32[0][31]) /
                            4
                        )}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 28
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 28
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.25vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    // borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      !link && onHandleChange(28, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[28]
                          : mpoR32[28]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][28]
                        : freShapR32[0][28]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      // onHandleChange(29, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[29]
                          : mpoR32[29]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][29]
                        : freShapR32[0][29]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      // onHandleChange(30, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[30]
                          : mpoR32[30]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][30]
                        : freShapR32[0][30]
                    }
                  />
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    onChange={(e) => {
                      // onHandleChange(31, e);
                    }}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? mpoL32[31]
                          : mpoR32[31]
                        : Left == LISTENING_SIDE.LEFT
                        ? freShapL32[0][31]
                        : freShapR32[0][31]
                    }
                  />
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>7k</Typography>
                  <Typography>8k</Typography>
                </Box>
              </Grid>
            </>
          )}

          {((Left == LISTENING_SIDE.LEFT &&
            fittingLeft.device_type == DEVICES.BTE_OPTIMA) ||
            (Left == LISTENING_SIDE.RIGHT &&
              fitting.device_type == DEVICES.BTE_OPTIMA)) && (
            <>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(0);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 0
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 0
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][1]
                        : data8R[4][1]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][1]
                      : data8R[1][1]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 0
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 0
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    // border: "1px solid #DDDDDD",
                    // borderRight: "1px solid #C24747",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(60, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][1]
                          : data8R[4][1]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][1]
                        : data8R[1][1]
                    }
                    // color='#EDF0F7'
                  />
                </Grid>
                <Typography>0</Typography>
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(1);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 1
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 1
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 1
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 1
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][2]
                        : data8R[4][2]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][2]
                      : data8R[1][2]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    border: "1px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 1
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 1
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(61, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][2]
                          : data8R[4][2]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][2]
                        : data8R[1][2]
                    }
                  />
                </Grid>
                <Typography>0.4</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(2);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 2
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 2
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 2
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 2
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][3]
                        : data8R[4][3]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][3]
                      : data8R[1][3]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 2
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 2
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(62, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][3]
                          : data8R[4][3]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][3]
                        : data8R[1][3]
                    }
                  />
                </Grid>
                <Typography>0.7</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(3);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 3
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 3
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 3
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 3
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][4]
                        : data8R[4][4]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][4]
                      : data8R[1][4]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 3
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 3
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(63, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][4]
                          : data8R[4][4]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][4]
                        : data8R[1][4]
                    }
                  />
                </Grid>
                <Typography>1.1</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(4);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    borderTop: "8px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 4
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 4
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][5]
                        : data8R[4][5]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][5]
                      : data8R[1][5]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 4
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 4
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(64, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][5]
                          : data8R[4][5]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][5]
                        : data8R[1][5]
                    }
                  />
                </Grid>
                <Typography>1.8</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(5);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 5
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 5
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 5
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 5
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][6]
                        : data8R[4][6]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][6]
                      : data8R[1][6]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    border: "1px solid #DDDDDD",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 5
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 5
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(65, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][6]
                          : data8R[4][6]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][6]
                        : data8R[1][6]
                    }
                  />
                </Grid>
                <Typography>3k</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(6);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.2vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 6
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 6
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 6
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 6
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][7]
                        : data8R[4][7]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][7]
                      : data8R[1][7]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 6
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 6
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.2vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    onChange={(e) => {
                      !link && onHandleChange(66, e);
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][7]
                          : data8R[4][7]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][7]
                        : data8R[1][7]
                    }
                  />
                </Grid>
                <Typography>5k</Typography>{" "}
              </Grid>
              <Grid xs={1.35} md={1.35}>
                <Box
                  onClick={() => {
                    !(fitting.read_only || fittingLeft.read_only) &&
                      setSliderButton(7);
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid #DDDDDD",
                    height: "5vh",
                    width: "10.26vw",
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 7
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 7
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    borderTop:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 7
                          ? "8px solid #C24747"
                          : "8px solid #DDDDDD"
                        : sliderButton == 7
                        ? "8px solid rgba(18, 101, 161, 1)"
                        : "8px solid #DDDDDD",
                  }}
                >
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {value == 1
                      ? Left == LISTENING_SIDE.LEFT
                        ? data8L[4][8]
                        : data8R[4][8]
                      : Left == LISTENING_SIDE.LEFT
                      ? data8L[1][8]
                      : data8R[1][8]}
                  </Typography>
                </Box>
                <Grid
                  // xs={1.5}
                  // md={2}

                  // gap={1}
                  sx={{
                    backgroundColor:
                      Left != LISTENING_SIDE.LEFT
                        ? sliderButton == 7
                          ? "rgba(252, 247, 247, 1)"
                          : ""
                        : sliderButton == 7
                        ? "rgba(246, 251, 254, 1)"
                        : "",
                    border: "1px solid #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "10.25vw",
                    height: "25vh",
                    scrollbarWidth: "none",
                    // borderRight: "1px solid #DDDDDD",
                  }}
                >
                  <Slider
                    disabled={fitting.read_only || fittingLeft.read_only}
                    sx={{
                      height: "20vh",
                      color:
                        Left != LISTENING_SIDE.LEFT ? "#C24747" : "#1265A1",
                    }}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    min={value == 0 ? 0 : 70}
                    onChange={(e) => {
                      !link && onHandleChange(67, e);
                    }}
                    max={value == 0 ? 50 : 130}
                    value={
                      value == 1
                        ? Left == LISTENING_SIDE.LEFT
                          ? data8L[4][8]
                          : data8R[4][8]
                        : Left == LISTENING_SIDE.LEFT
                        ? data8L[1][8]
                        : data8R[1][8]
                    }
                  />
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>7k</Typography>
                  <Typography>8k</Typography>
                </Box>
              </Grid>
            </>
          )}

          <Box
            ml={2}
            onClick={() => {
              setLeft(LISTENING_SIDE.LEFT);
            }}
            sx={{
              visibility:
                Left == LISTENING_SIDE.RIGHT
                  ? fittingLeft.connected
                    ? ""
                    : "hidden"
                  : "hidden",
              cursor: "pointer",
              backgroundColor: "#1265A1",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }}> */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 17.5L9.5 9.5L1.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>
        </Grid>
        <Box mt={1} gap={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={fitting.read_only || fittingLeft.read_only}
            sx={{ width: "100px", height: "5vh" }}
            variant="outlined"
            onClick={() => {
              if (link) {
                onIncreament(LISTENING_SIDE.LEFT);
                onIncreament(LISTENING_SIDE.RIGHT);
              } else {
                onIncreament(Left);
              }
            }}
          >
            <KeyboardArrowUpIcon fontSize="large" />
          </Button>
          <Button
            disabled={fitting.read_only || fittingLeft.read_only}
            sx={{ width: "100px", height: "5vh" }}
            variant="outlined"
            onClick={() => {
              if (link) {
                onDecreament(LISTENING_SIDE.LEFT);
                onDecreament(LISTENING_SIDE.RIGHT);
              } else {
                onDecreament(Left);
              }
            }}
          >
            <KeyboardArrowDownIcon fontSize="large" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FineTunningController;
