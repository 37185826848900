import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import mode1 from "../../assets/images/mode1.svg";
import mode2 from "../../assets/images/mode2.svg";
import mode3 from "../../assets/images/mode3.svg";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import mode4 from "../../assets/images/mode4.svg";
import React, { useEffect, useState } from "react";
import {
  LISTENING_SIDE,
  MANAGE_PROGRAM,
  MANAGE_PROGRAM_LIST,
  MANAGE_PROGRAM_LIST_Array,
  MANAGE_PROGRAM_NAME,
} from "../../utils/patient.constants";
import { useDispatch, useSelector } from "react-redux";
import { changeMode } from "../../store/actions/fittingAction";

const ModeComponent = ({
  modeKey,
  modeNumber,
  setModeNumber,
  step,
  setStep,
  modeType,
  setModeType,
  setShouldUpdate,
  onSaveFitting,
}) => {
  const { fitting, fittingLeft } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [onModeChange, setOnModeChange] = useState(false);

  useEffect(() => {
    if (onModeChange) {
      onSaveFitting();
      setOnModeChange(false);
    }
  }, [onModeChange]);

  const onHandle = () => {
    const defaultArrayLeft = [...fittingLeft.mode_data[0].data];
    defaultArrayLeft[0] = modeNumber;
    const defaultArrayRight = [...fitting.mode_data[0].data];
    defaultArrayRight[0] = modeNumber;
    const leftdataObject = {};
    const rightdataObject = {};

    console.log("this is mode type", modeType);

    leftdataObject["mode_type"] = modeType;
    rightdataObject["mode_type"] = modeType;

    leftdataObject["master_gain"] = 0;
    leftdataObject["noise_reduction"] = 0;
    leftdataObject["loudness_compression"] = 0;
    leftdataObject["self_speech_reduction"] = 0;
    leftdataObject["sharpness"] = 0;
    leftdataObject["wind_noise_reduction"] = 0;

    rightdataObject["master_gain"] = 0;
    rightdataObject["noise_reduction"] = 0;
    rightdataObject["loudness_compression"] = 0;
    rightdataObject["self_speech_reduction"] = 0;
    rightdataObject["sharpness"] = 0;
    rightdataObject["wind_noise_reduction"] = 0;

    switch (modeType) {
      case MANAGE_PROGRAM_LIST.COMMON:
        break;
      case MANAGE_PROGRAM_LIST.MUSIC:
        defaultArrayLeft[36] = defaultArrayLeft[36] * 1.4;
        defaultArrayLeft[37] = defaultArrayLeft[37] * 1.4;
        defaultArrayLeft[38] = defaultArrayLeft[38] * 1.15;
        defaultArrayLeft[39] = defaultArrayLeft[39] * 1.15;
        defaultArrayLeft[40] = defaultArrayLeft[40] * 1.05;
        defaultArrayLeft[41] = defaultArrayLeft[41] * 1;
        defaultArrayLeft[42] = defaultArrayLeft[42] * 0.85;
        defaultArrayLeft[43] = defaultArrayLeft[43] * 0.8;

        defaultArrayLeft[52] = defaultArrayLeft[52] - 0.3;
        defaultArrayLeft[53] = defaultArrayLeft[53] - 0.3;
        defaultArrayLeft[54] = defaultArrayLeft[54] - 0.3;
        defaultArrayLeft[55] = defaultArrayLeft[55] - 0.3;
        defaultArrayLeft[56] = defaultArrayLeft[56] - 0.1;
        defaultArrayLeft[57] = defaultArrayLeft[57] - 0.1;
        defaultArrayLeft[58] = defaultArrayLeft[58] + 0.1;
        defaultArrayLeft[59] = defaultArrayLeft[59] + 0.1;

        defaultArrayLeft[80] = 0;
        defaultArrayLeft[81] = 0;
        defaultArrayLeft[82] = 0;
        defaultArrayLeft[83] = 0;
        defaultArrayLeft[84] = 0;
        defaultArrayLeft[85] = 0;
        defaultArrayLeft[86] = 10;
        defaultArrayLeft[87] = 20;

        defaultArrayRight[36] = defaultArrayRight[36] * 1.4;
        defaultArrayRight[37] = defaultArrayRight[37] * 1.4;
        defaultArrayRight[38] = defaultArrayRight[38] * 1.15;
        defaultArrayRight[39] = defaultArrayRight[39] * 1.15;
        defaultArrayRight[40] = defaultArrayRight[40] * 1.05;
        defaultArrayRight[41] = defaultArrayRight[41] * 1;
        defaultArrayRight[42] = defaultArrayRight[42] * 0.85;
        defaultArrayRight[43] = defaultArrayRight[43] * 0.8;

        defaultArrayRight[52] = defaultArrayRight[52] - 0.3;
        defaultArrayRight[53] = defaultArrayRight[53] - 0.3;
        defaultArrayRight[54] = defaultArrayRight[54] - 0.3;
        defaultArrayRight[55] = defaultArrayRight[55] - 0.3;
        defaultArrayRight[56] = defaultArrayRight[56] - 0.1;
        defaultArrayRight[57] = defaultArrayRight[57] - 0.1;
        defaultArrayRight[58] = defaultArrayRight[58] + 0.1;
        defaultArrayRight[59] = defaultArrayRight[59] + 0.1;

        defaultArrayRight[80] = 0;
        defaultArrayRight[81] = 0;
        defaultArrayRight[82] = 0;
        defaultArrayRight[83] = 0;
        defaultArrayRight[84] = 0;
        defaultArrayRight[85] = 0;
        defaultArrayRight[86] = 10;
        defaultArrayRight[87] = 20;

        leftdataObject["data"] = defaultArrayLeft;
        rightdataObject["data"] = defaultArrayRight;
        break;
      case MANAGE_PROGRAM_LIST.NOISE_CANCELLATION:
        defaultArrayLeft[20] = defaultArrayLeft[20] - 0.4;
        defaultArrayLeft[21] = defaultArrayLeft[21] - 0.3;
        defaultArrayLeft[22] = defaultArrayLeft[22] - 0.3;
        defaultArrayLeft[23] = defaultArrayLeft[23] - 0.3;
        defaultArrayLeft[24] = defaultArrayLeft[24] - 0.2;
        defaultArrayLeft[25] = defaultArrayLeft[25] - 0.2;
        defaultArrayLeft[26] = defaultArrayLeft[26] - 0.2;
        defaultArrayLeft[27] = defaultArrayLeft[27] - 0.4;

        for (let i = 36; i <= 43; i++) {
          defaultArrayLeft[i] = defaultArrayLeft[i] * 0.8;
        }

        defaultArrayLeft[52] = defaultArrayLeft[52] + 0.2;
        defaultArrayLeft[53] = defaultArrayLeft[53] + 0.2;
        defaultArrayLeft[54] = defaultArrayLeft[54] + 0.2;
        defaultArrayLeft[55] = defaultArrayLeft[55] + 0.2;
        defaultArrayLeft[56] = defaultArrayLeft[56] + 0.3;
        defaultArrayLeft[57] = defaultArrayLeft[57] + 0.3;
        defaultArrayLeft[58] = defaultArrayLeft[58] + 0.4;
        defaultArrayLeft[59] = defaultArrayLeft[59] + 0.4;

        defaultArrayLeft[80] = 7;
        defaultArrayLeft[81] = 5;
        defaultArrayLeft[82] = 10;
        defaultArrayLeft[83] = 7;
        defaultArrayLeft[84] = 0;
        defaultArrayLeft[85] = 0;
        defaultArrayLeft[86] = 10;
        defaultArrayLeft[87] = 20;

        defaultArrayRight[20] = defaultArrayRight[20] - 0.4;
        defaultArrayRight[21] = defaultArrayRight[21] - 0.3;
        defaultArrayRight[22] = defaultArrayRight[22] - 0.3;
        defaultArrayRight[23] = defaultArrayRight[23] - 0.3;
        defaultArrayRight[24] = defaultArrayRight[24] - 0.2;
        defaultArrayRight[25] = defaultArrayRight[25] - 0.2;
        defaultArrayRight[26] = defaultArrayRight[26] - 0.2;
        defaultArrayRight[27] = defaultArrayRight[27] - 0.4;

        for (let i = 36; i <= 43; i++) {
          defaultArrayRight[i] = defaultArrayRight[i] * 0.8;
        }

        defaultArrayRight[52] = defaultArrayRight[52] + 0.2;
        defaultArrayRight[53] = defaultArrayRight[53] + 0.2;
        defaultArrayRight[54] = defaultArrayRight[54] + 0.2;
        defaultArrayRight[55] = defaultArrayRight[55] + 0.2;
        defaultArrayRight[56] = defaultArrayRight[56] + 0.3;
        defaultArrayRight[57] = defaultArrayRight[57] + 0.3;
        defaultArrayRight[58] = defaultArrayRight[58] + 0.4;
        defaultArrayRight[59] = defaultArrayRight[59] + 0.4;

        defaultArrayRight[80] = 7;
        defaultArrayRight[81] = 5;
        defaultArrayRight[82] = 10;
        defaultArrayRight[83] = 7;
        defaultArrayRight[84] = 0;
        defaultArrayRight[85] = 0;
        defaultArrayRight[86] = 10;
        defaultArrayRight[87] = 20;

        leftdataObject["data"] = defaultArrayLeft;
        rightdataObject["data"] = defaultArrayRight;
        break;

      case MANAGE_PROGRAM_LIST.SPEECH_IN_NOISE:
        defaultArrayLeft[22] = defaultArrayLeft[22] + 0.1;
        defaultArrayLeft[23] = defaultArrayLeft[23] + 0.1;
        defaultArrayLeft[24] = defaultArrayLeft[24] + 0.1;

        defaultArrayLeft[36] = defaultArrayLeft[36] * 1.2;
        defaultArrayLeft[37] = defaultArrayLeft[37] * 1.2;
        defaultArrayLeft[38] = defaultArrayLeft[38] * 1.1;
        defaultArrayLeft[39] = defaultArrayLeft[39] * 1.1;
        defaultArrayLeft[40] = defaultArrayLeft[40] * 1.1;
        defaultArrayLeft[41] = defaultArrayLeft[41] * 1.05;
        defaultArrayLeft[42] = defaultArrayLeft[42] * 1.1;
        defaultArrayLeft[43] = defaultArrayLeft[43] * 1.1;

        defaultArrayLeft[53] = defaultArrayLeft[53] - 0.1;
        defaultArrayLeft[54] = defaultArrayLeft[54] - 0.1;
        defaultArrayLeft[55] = defaultArrayLeft[55] - 0.1;
        defaultArrayLeft[56] = defaultArrayLeft[56] - 0.1;
        defaultArrayLeft[57] = defaultArrayLeft[57] - 0.1;

        defaultArrayLeft[80] = 10;
        defaultArrayLeft[81] = 10;
        defaultArrayLeft[82] = 10;
        defaultArrayLeft[83] = 7;
        defaultArrayLeft[84] = 0;
        defaultArrayLeft[85] = 0;
        defaultArrayLeft[86] = 10;
        defaultArrayLeft[87] = 20;

        defaultArrayRight[22] = defaultArrayRight[22] + 0.1;
        defaultArrayRight[23] = defaultArrayRight[23] + 0.1;
        defaultArrayRight[24] = defaultArrayRight[24] + 0.1;

        defaultArrayRight[36] = defaultArrayRight[36] * 1.2;
        defaultArrayRight[37] = defaultArrayRight[37] * 1.2;
        defaultArrayRight[38] = defaultArrayRight[38] * 1.1;
        defaultArrayRight[39] = defaultArrayRight[39] * 1.1;
        defaultArrayRight[40] = defaultArrayRight[40] * 1.1;
        defaultArrayRight[41] = defaultArrayRight[41] * 1.05;
        defaultArrayRight[42] = defaultArrayRight[42] * 1.1;
        defaultArrayRight[43] = defaultArrayRight[43] * 1.1;

        defaultArrayRight[53] = defaultArrayRight[53] - 0.1;
        defaultArrayRight[54] = defaultArrayRight[54] - 0.1;
        defaultArrayRight[55] = defaultArrayRight[55] - 0.1;
        defaultArrayRight[56] = defaultArrayRight[56] - 0.1;
        defaultArrayRight[57] = defaultArrayRight[57] - 0.1;

        defaultArrayRight[80] = 10;
        defaultArrayRight[81] = 10;
        defaultArrayRight[82] = 10;
        defaultArrayRight[83] = 7;
        defaultArrayRight[84] = 0;
        defaultArrayRight[85] = 0;
        defaultArrayRight[86] = 10;
        defaultArrayRight[87] = 20;

        leftdataObject["data"] = defaultArrayLeft;
        rightdataObject["data"] = defaultArrayRight;
        break;
      default:
    }

    dispatch(changeMode(leftdataObject, LISTENING_SIDE.LEFT));
    dispatch(changeMode(rightdataObject, LISTENING_SIDE.RIGHT));
    setStep(0);
    setModeNumber(null);
    setShouldUpdate(true);
    setOnModeChange(true);
  };

  return (
    <>
      {step === 2 && modeKey === modeNumber && (
        <Box
          sx={{
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
            width: "19vw",
            height: "300px",
          }}
        >
          <Box
            flexDirection={"column"}
            height={"35vh"}
            display={"flex"}
            alignItems={"space-between"}
            justifyContent={"center"}
          >
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  color: "#4D4D4D",
                  fontWeight: "600",
                  fontFamily: "League Spartan",
                }}
              >
                Do you want to change the Mode to{" "}
                {MANAGE_PROGRAM_NAME[modeType]} ?
              </Typography>
            </Box>
            <Box
              p={2}
              sx={{
                width: "18.9vw",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  color: "#4D4D4D",
                  fontWeight: "500",
                  fontFamily: "League Spartan",
                }}
              >
                If you change the mode, all configuration of this mode will be
                changed.
              </Typography>
            </Box>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"flex-end"}
          >
            <Button
              onClick={onHandle}
              sx={{
                width: "8.7vw",
                border: "1px solid #2D3B67",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#2D3B67",
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "League spartan",
                }}
              >
                Yes
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setStep(0);
                setModeNumber(null);
              }}
              sx={{
                width: "8.7vw",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#808080",
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "League spartan",
                }}
              >
                No
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
      {step === 1 && modeKey === modeNumber && (
        <Box
          sx={{
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
            width: "19vw",
            height: "300px",
            overflow: "auto",
            scrollBehavior: "smooth",
          }}
        >
          {MANAGE_PROGRAM_LIST_Array.map((obj, index) => (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                borderBottom: "1px solid #DDDDDD",
                alignItems: "center",
                height: "50px",
              }}
            >
              <Button
                onClick={() => {
                  setModeType(obj.value);
                  setStep(2);
                }}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  {MANAGE_PROGRAM_NAME[obj.value]}
                </Typography>
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

const ManageProgramController = ({ setShouldUpdate, onSaveFitting }) => {
  const [step, setStep] = useState(0);
  const [modeType, setModeType] = useState(0);
  const [modeNumber, setModeNumber] = useState(null);

  const onChange = () => {};

  const { fitting, fittingLeft } = useSelector((state) => state);

  return (
    <Box mt={4} ml={6}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M1
            </Typography>
          </Box>
          {modeNumber != 0 && (
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  Common
                </Typography>
                <IconButton
                  disabled={true}
                  onClick={() => {
                    setStep(1);
                    setModeNumber(0);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode2} />
              </Box>
            </Box>
          )}
          <ModeComponent
            setShouldUpdate={setShouldUpdate}
            modeNumber={modeNumber}
            setModeNumber={setModeNumber}
            modeKey={0}
            modeType={modeType}
            setModeType={setModeType}
            step={step}
            setStep={setStep}
          />
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M2
            </Typography>
          </Box>
          {modeNumber != 1 && (
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  {
                    MANAGE_PROGRAM_NAME[
                      fitting?.mode_data[1]?.mode_type ??
                        fittingLeft?.mode_data[1]?.mode_type ??
                        1
                    ]
                  }
                </Typography>
                <IconButton
                  onClick={() => {
                    setModeNumber(1);
                    setStep(1);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode2} />
              </Box>
            </Box>
          )}
          <ModeComponent
            setShouldUpdate={setShouldUpdate}
            modeNumber={modeNumber}
            setModeNumber={setModeNumber}
            modeKey={1}
            modeType={modeType}
            setModeType={setModeType}
            step={step}
            setStep={setStep}
            onSaveFitting={onSaveFitting}
          />
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M3
            </Typography>
          </Box>
          {modeNumber != 2 && (
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  {
                    MANAGE_PROGRAM_NAME[
                      fitting?.mode_data[2]?.mode_type ??
                        fittingLeft?.mode_data[2]?.mode_type ??
                        1
                    ]
                  }
                </Typography>
                <IconButton
                  onClick={() => {
                    setModeNumber(2);
                    setStep(1);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode2} />
              </Box>
            </Box>
          )}
          <ModeComponent
            setShouldUpdate={setShouldUpdate}
            modeNumber={modeNumber}
            setModeNumber={setModeNumber}
            modeKey={2}
            modeType={modeType}
            setModeType={setModeType}
            step={step}
            setStep={setStep}
            onSaveFitting={onSaveFitting}
          />
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M4
            </Typography>
          </Box>
          {modeNumber != 3 && (
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  {
                    MANAGE_PROGRAM_NAME[
                      fitting?.mode_data[3]?.mode_type ??
                        fittingLeft?.mode_data[3]?.mode_type ??
                        1
                    ]
                  }
                </Typography>
                <IconButton
                  onClick={() => {
                    setModeNumber(3);
                    setStep(1);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode2} />
              </Box>
            </Box>
          )}
          <ModeComponent
            setShouldUpdate={setShouldUpdate}
            modeNumber={modeNumber}
            setModeNumber={setModeNumber}
            modeKey={3}
            modeType={modeType}
            setModeType={setModeType}
            step={step}
            setStep={setStep}
            onSaveFitting={onSaveFitting}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManageProgramController;
