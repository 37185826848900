import { Autocomplete, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import SubmitButton from "../../components/button/SubmitButton"

import CustomInput from "../../components/inputs/CustomInput"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { closeModal } from "../../store/actions/modalAction"
import { THIRD_PART_APPS_USED, USER_ROLES } from "../../utils/constants"
import { findNameByRole, titleCase, toTitleCase } from "../../utils/helper"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getOpenSolarUserApi } from "../../apis/user.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id="secret"
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {!isUpdate && <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.key_name}
                        onChange={(e) => setFields({ ...fields, err: '', key_name: e.target.value })}
                        type="text"
                        label={"Key Name*"}

                    />}

                    {<CustomInput
                        disabled={loading}
                        value={fields.key_value}
                        onChange={(e) => setFields({ ...fields, err: '', key_value: e.target.value })}
                        type="text"
                        label={"Key Value*"}

                    />}



                    {!isUpdate && <Autocomplete

                        disableClearable

                        value={fields.third_party_app ? titleCase(fields.third_party_app) : null}

                        onChange={(e, newVal) => {
                            setFields({ ...fields, third_party_app: newVal ? newVal._id : null })
                        }}
                        options={[...Object.keys(THIRD_PART_APPS_USED).map((key) => ({ label: titleCase(key), _id: THIRD_PART_APPS_USED[key] }))]}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Third Party App*" {...params} label="Select Third Party App*" />}
                    />}


                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)