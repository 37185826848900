import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import BTEDevice from "../../assets/images/bte.png";
import { useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import { WDRC_circuit_gain } from "../../utils/j10.helper";
import { findMediun, findMediunsInBulk } from "../../utils/helper";
import RightEar from "../../assets/images/Left (1).png";
import LeftEar from "../../assets/images/Left (2).png";
import SPEAKERIMAGE from "../../assets/images/speaker.svg";
import MUTEIMAGE from "../../assets/images/muteimg.svg";

const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}));

const createData = (envDb, fittingData, deviceType, edgeVal) => {
  const defaultDSummyValueSet = [0.6, 48, edgeVal, 70, 2, 100];
  // console.log("this is device type", deviceType);

  const data = [];

  // const round =
  //   deviceType == DEVICES.BTE_PRIME || deviceType == DEVICES.BTE_24
  //     ? 2
  //     : deviceType == DEVICES.BTE_16
  //     ? 1
  //     : 0;

  const round = 0;

  // console.log("this is rounde", round);

  // for (let i = 0; i < 8; i++) {

  //     const startVal = 20

  //     const val = WDRC_circuit_gain(
  //         envDb,
  //         fittingData[startVal + 0 + i],
  //         fittingData[startVal + 8 + i],
  //         fittingData[startVal + 16 + i],
  //         fittingData[startVal + 24 + i],
  //         fittingData[startVal + 32 + i],
  //         fittingData[startVal + 40 + i],
  //     )

  //     data.push(val[0] - envDb)
  // }

  const startVal = 20;

  const temp = [[], [], [], [], [], []];
  for (let i = 0; i < 8; i++) {
    temp[0].push(fittingData[startVal + 0 + i]);
    temp[1].push(fittingData[startVal + 8 + i]);
    temp[2].push(fittingData[startVal + 16 + i]);
    temp[3].push(fittingData[startVal + 24 + i]);
    temp[4].push(fittingData[startVal + 32 + i]);
    temp[5].push(fittingData[startVal + 40 + i]);
  }

  const finalValArray = [
    findMediunsInBulk(temp[0], defaultDSummyValueSet[0], round),
    findMediunsInBulk(temp[1], defaultDSummyValueSet[1], round),
    findMediunsInBulk(temp[2], defaultDSummyValueSet[2], round),
    findMediunsInBulk(temp[3], defaultDSummyValueSet[3], round),
    findMediunsInBulk(temp[4], defaultDSummyValueSet[4], round),
    findMediunsInBulk(temp[5], defaultDSummyValueSet[5], round),
  ];
  if (deviceType == DEVICES.BTE_24) {
    console.log("this is run now ...........................................")
    for (let i = 0; i < finalValArray.length; i++) {
      const temp = [...finalValArray[i]];
      const newArray = temp.filter((item, index) => (index + 1) % 4 != 0);
      finalValArray[i] = newArray;
    }
  }

  for (let i = 0; i < finalValArray[0].length; i++) {
    const val = WDRC_circuit_gain(
      envDb,
      finalValArray[0][i] / 10,
      finalValArray[1][i],
      finalValArray[2][i],
      finalValArray[3][i],
      finalValArray[4][i] / 10,
      finalValArray[5][i]
    );

    data.push(val[0] - envDb);
  }

  // console.log("tyhis is data for chart", data);
  return data;
};

const ChartComponent = ({ edgeVal, fitting }) => {
  const labels = useMemo(() => {
    if (fitting.device_type == DEVICES.BTE_16)
      return [
        "125",
        "221",
        "317",
        "410",
        "502",
        "650",
        "797",
        "1031",
        "1264",
        "1635",
        "2005",
        "2593",
        "3181",
        "4113",
        "5044",
        // "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_24)
      return [
        "125",
        "189",
        "253",
        "317",
        "379",
        "441",
        "502",
        "601",
        "700",
        "797",
        "953",
        "1109",
        "1264",
        "1511",
        "1758",
        "2005",
        "2397",
        "2789",
        "3181",
        "3802",
        "4423",
        "5044",
        "6522",
        "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_PRIME)
      return ["", "250", "500", "1k", "2k", "4k", "6k", "8k"];
    else return ["", "250", "500", "1k", "2k", "4k", "6k", "8k"];
  }, [fitting.device_type]);

  const dataFields = useMemo(
    () => ({
      labels,
      datasets: fitting.connected
        ? [
            {
              label: "Pink Area Upper Bound",
              data: [
                120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120,
                120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120,
                120, 120, 120, 120, 120, 120, 120,
              ],
              borderColor: "rgba(0, 0, 0, 0)",
              backgroundColor:
                fitting.device_side == LISTENING_SIDE.RIGHT
                  ? "rgba(238, 210, 210, 0.5 )"
                  : "rgba(229, 238, 244, 0.5)",
              // opacity:0.2,
              pointRadius: 0,
              fill: "+1",
            },
            {
              label: "Pink Area Lower Bound",
              data: [
                70, 63, 66, 65, 71, 63, 65, 54, 70, 63, 66, 65, 71, 63, 65, 54,
                70, 63, 66, 65, 71, 63, 65, 54, 70, 63, 66, 65, 71, 63, 65, 54,
                70, 63,
              ],
              borderColor:
                fitting.device_side == LISTENING_SIDE.RIGHT
                  ? "#C24747"
                  : "#1265A1",
              backgroundColor: "rgba(200, 200, 200, 0)",
              opacity: 0.2,
              pointRadius: 0,
              fill: "origin",
            },
            {
              label: "50dB",
              data: createData(50, fitting.data, fitting.device_type, edgeVal),
              pointRadius: 0,
              borderColor: "#4A4A4A",
            },
            {
              label: "65dB",
              data: createData(65, fitting.data, fitting.device_type, edgeVal),
              pointRadius: 0,
              borderColor: "#969696",
            },
            {
              label: "80dB",
              data: createData(80, fitting.data, fitting.device_type, edgeVal),
              pointRadius: 0,
              // fill: "start",
              borderColor: "#CCCCCC",
              // pointBackgroundColor: "white",
              // backgroundColor: (context) => {
              //     // const ctx = context.chart.ctx;
              //     // const gradient = ctx.createLinearGradient(50, 0, 0, 200);
              //     // gradient.addColorStop(0, "rgba(250,174,50,0.1)");
              //     // gradient.addColorStop(1, "rgba(250,174,50,0.1)");
              //     // return gradient;
              // },
            },
          ]
        : [],
    }),
    [fitting.data, fitting.device_type, edgeVal]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10,
            color: "#666666", // Font size for x-axis labels
          },
          // Padding to move the labels outside the box
        },
      },
      y: {
        min: -10,
        max: 80,
        reverse: false,
        ticks: {
          callback: function (value) {
            return value;
          },
          stepSize: 10,
          autoSkip: false,
          font: {
            size: 8,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        filter: function (tooltipItem) {
          return (
            tooltipItem.datasetIndex === 2 ||
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 4
          );
        },
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.4,
      },
    },
  };

  return (
    <Box
      sx={{
        minHeight: "35vh",
        height: "40vh",
        // minWidth: "30vw",
        // width: "38vw",
      }}
    >
      <Line options={options} data={dataFields} />
    </Box>
  );
};

// const ChartComponent = ({ edgeVal }) => {
//   const { fitting } = useSelector((state) => state);

//   const labels = useMemo(() => {
//     if (fitting.device_type == DEVICES.BTE_16)
//       return [
//         "125",
//         "221",
//         "317",
//         "410",
//         "502",
//         "650",
//         "797",
//         "1031",
//         "1264",
//         "1635",
//         "2005",
//         "2593",
//         "3181",
//         "4113",
//         "5044",
//         // "8000",
//       ];
//     else if (fitting.device_type == DEVICES.BTE_24)
//       return [
//         "125",
//         "189",
//         "253",
//         "317",
//         "379",
//         "441",
//         "502",
//         "601",
//         "700",
//         "797",
//         "953",
//         "1109",
//         "1264",
//         "1511",
//         "1758",
//         "2005",
//         "2397",
//         "2789",
//         "3181",
//         "3802",
//         "4423",
//         "5044",
//         "6522",
//         "8000",
//       ];
//     else if (fitting.device_type == DEVICES.BTE_32)
//       return [
//         "125",
//         "173",
//         "221",
//         "269",
//         "317",
//         "364",
//         "410",
//         "456",

//         "502",
//         "576",
//         "650",
//         "723",
//         "797",
//         "914",
//         "1031",
//         "1148",

//         "1264",
//         "1450",
//         "1635",
//         "1820",
//         "2005",
//         "3000",
//         "2593",
//         "2860",

//         "3181",
//         "3647",
//         "4113",
//         "4578",
//         "5044",
//         "6522",
//         "7015",
//         "8000",
//       ];
//     else return ["-10", "250", "500", "1k", "2k", "4k", "6k", "8k"];
//   }, [fitting.device_type]);

//   const dataFields = useMemo(
//     () => ({
//       labels,
//       datasets: [
//         {
//           label: "40dB",
//           data: createData(40, fitting.data, fitting.device_type, edgeVal),

//           borderColor: "blue",
//         },
//         {
//           label: "65dB",
//           data: createData(65, fitting.data, fitting.device_type, edgeVal),

//           borderColor: "red",
//         },
//         {
//           label: "95dB",
//           data: createData(95, fitting.data, fitting.device_type, edgeVal),
//           // fill: "start",
//           borderColor: "green",
//           // pointBackgroundColor: "white",
//           // backgroundColor: (context) => {
//           //     // const ctx = context.chart.ctx;
//           //     // const gradient = ctx.createLinearGradient(50, 0, 0, 200);
//           //     // gradient.addColorStop(0, "rgba(250,174,50,0.1)");
//           //     // gradient.addColorStop(1, "rgba(250,174,50,0.1)");
//           //     // return gradient;
//           // },
//         },
//       ],
//     }),
//     [fitting.data, fitting.device_type, edgeVal]
//   );

//   return (
//     <Line
//       maxWidth="100%"
//       maxHeight="100%"
//       options={{
//         interaction: {
//           mode: "index",
//         },
//         plugins: {
//           datalabels: {
//             display: false,
//             backgroundColor: "red",
//           },

//           legend: {
//             enabled: false,
//             color: "red",
//             position: "bottom",
//             labels: {
//               // color:"red"
//             },
//           },
//         },

//         responsive: true,
//         animation: false,

//         bezierCurve: true,
//         elements: {
//           // line: {
//           //     borderWidth: 1,
//           //     tension: 0.5,
//           //     // borderColor: "#00F",
//           //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           // },
//           // point: {
//           //     pointStyle: title == "Right Ear" ? "circle" : "crossRot",
//           //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     radius: title == "Right Ear" ? 6 : 10,
//           //     backgroundColor: "white",
//           //     hoverBackgroundColor: "white",
//           //     hoverRadius: title == "Right Ear" ? 8 : 12,
//           //     borderWidth: title == "Right Ear" ? 1 : 3,
//           //     hoverBorderWidth: title == "Right Ear" ? 1 : 3,
//           // },
//         },
//         spanGaps: 1,
//         aspectRatio: 1.5,
//         scales: {
//           y: {
//             title: {
//               display: true,
//               text: "Gain",
//               fontWeight: "600",
//               color: "#3C508B",
//               heigth:"50vh"
//             },
//             max: 120,
//             min: -10,
//             suggestedMax: 0,
//             suggestedMin: 80,
//             // ticks: {
//             //     callback: function (value, index, ticks) {

//             //         return -1 * (value)
//             //     }
//             // }
//           },
//           x: {
//             title: {
//               display: true,
//               text: "Frequency",
//               fontWeight: "6001",
//               color: "#3C508B",
//             },
//           },
//         },

//         onClick: (e, ele, cha1rt) => {},
//         onHover: (e, ele, chart) => {},
//       }}
//       data={dataFields}
//     />
//   );
// };

const FittingChartArea = ({ edgeVal, fitting, mutedHandle }) => {
  // const { fitting } = useSelector(state => state)
  // console.log("object",fitting);
  return (
    <Box p={1}>
      <Grid md={12}>
        {fitting.connected ? (
          <Grid display={"flex"} flexDirection={"row"}>
            <Box>
              <img
                src={
                  fitting.device_side === LISTENING_SIDE.RIGHT
                    ? LeftEar
                    : RightEar
                }
                style={{ height: "4vh" }}
              />
            </Box>
            <Box mt={1}>
              <Typography
                variant="caption"
                sx={{
                  color: "#1A1A1A",
                  fontFamily: "League spartan",
                  fontWeight: "bold",
                  fontStyle: "normal",
                }}
              >
                {fitting.device_type === DEVICES.BTE_OPTIMA
                  ? " BTE OPTIMA"
                  : "BTE PRIME"}
              </Typography>
            </Box>
            {!(fitting.read_only || fitting.remote) && (
              <Box ml={0} display={"flex"}>
                <Typography
                  variant="caption"
                  sx={{
                    color: "#999",
                    fontFamily: "League spartan",
                    fontWeight: "bold",
                    fontStyle: "normal",
                  }}
                >
                  -
                </Typography>
              </Box>
            )}
            {!(fitting.read_only || fitting.remote) && (
              <Box mt={1} display={"flex"}>
                <Typography
                  variant="caption"
                  sx={{
                    color: "#39AC8A",
                    fontFamily: "League spartan",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  • Connected
                </Typography>
              </Box>
            )}
            <Box mt={1} ml={1}>
              <Typography
                variant="caption"
                sx={{
                  color: "#2D3B67",
                  fontFamily: "League Spartan",
                  fontWeight: "400",
                  fontStyle: "normal",
                }}
              >
                02567(Serial Number)
              </Typography>
            </Box>
            {!(fitting.remote || fitting.read_only) && (
              <Box>
                {!fitting.data[3] == 0 ? (
                  <IconButton
                    sx={{
                      border: "1px solid #2D3B67",
                      borderRadius: "100px",
                      backgroundColor: "rgba(229, 238, 244, 0.5)",
                      width: "30px",
                      height: "30px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      mutedHandle(fitting);
                    }}
                  >
                    <img src={SPEAKERIMAGE} alt="SPEAKERIMAGE" />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      border: "1px solid #2D3B67",
                      borderRadius: "100px",
                      backgroundColor: "rgba(229, 238, 244, 0.5)",
                      width: "30px",
                      height: "30px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      mutedHandle(fitting);
                    }}
                  >
                    <img src={MUTEIMAGE} alt="MUTEIMAGE" />
                  </IconButton>
                )}
              </Box>
            )}
          </Grid>
        ) : (
          <Typography mt={5}></Typography>
        )}
      </Grid>
      <Box>
        <ChartComponent fitting={fitting} edgeVal={edgeVal} />
      </Box>
    </Box>
  );
};

export default FittingChartArea;
