export const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

}

export const absolute = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

}